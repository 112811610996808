import { UserRoleType } from '@pages/userPages/redux/state';
import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { TabId } from '../redux/state';

import { AttributesConnect } from './attributes.connect';
import { RelationsConnect } from './relations.connect';
import { ChronologyConnect } from './chronology.connect';
import { SimilarConnect } from './similar.connect';
import { ReasonsConnect } from './reason.connect';
import { AIChatConnect } from './aiChat.connect';

export const tabTypes = {
  attributes: {
    id: TabId.attributes,
    connector: AttributesConnect,
    title: 'Атрибуты',
  },
  relations: {
    id: TabId.relations,
    connector: RelationsConnect,
    title: 'Нормы',
  },
  aiChat: {
    id: TabId.aiChat,
    connector: AIChatConnect,
    title: 'AI-ассистент',
  },
  reason: {
    id: TabId.reason,
    connector: ReasonsConnect,
    title: 'Доводы',
  },
  chronology: {
    id: TabId.chronology,
    connector: ChronologyConnect,
    title: 'Хронология',
  },
  similar: {
    id: TabId.similar,
    connector: SimilarConnect,
    title: 'Похожие',
  },
};

export function prepareTabs(state) {
  const {
    currentUser,
    document,
  } = state;

  const tabs = [];
  const additionTabs = [];
  tabs.push(tabTypes.attributes);

  const isReal = currentUser.role !== UserRoleType.guest;
  if (!isReal) {
    tabs.push(tabTypes.relations);

    return {
      tabs,
      additionTabs,
    };
  }

  const hasChat = currentUser.accessTo.includes('document_ai_assistant');
  const isDocument = document.id;

  if (!hasChat && !isDocument) {
    tabs.push(tabTypes.relations);
    tabs.push(tabTypes.reason);

    return {
      tabs,
      additionTabs,
    };
  }

  if (!hasChat) {
    tabs.push(tabTypes.relations);
    tabs.push(tabTypes.reason);
    additionTabs.push(tabTypes.chronology);
    additionTabs.push(tabTypes.similar);

    return {
      tabs,
      additionTabs,
    };
  }

  tabs.push(tabTypes.reason);
  tabs.push(tabTypes.aiChat);
  additionTabs.push(tabTypes.relations);
  additionTabs.push(tabTypes.chronology);
  additionTabs.push(tabTypes.similar);

  return {
    tabs,
    additionTabs,
  };
}

export function sendAmplitude(id) {
  if (id === tabTypes.aiChat.id) {
    sendAmplitudeData('document: open aiChat tab');
    Userpilot.track('document: open aiChat tab');
  }

  if (id === tabTypes.reason.id) {
    sendAmplitudeData('document: open reason tab');
    Userpilot.track('document: open reason tab');
  }

  if (id === tabTypes.similar.id) {
    sendAmplitudeData('document: open similar tab');
    Userpilot.track('document: open similar tab');
  }

  if (id === tabTypes.chronology.id) {
    sendAmplitudeData('document: open chronology tab');
    Userpilot.track('document: open chronology tab');
  }
}

export default tabTypes;
