import React, { useState } from 'react';

import { IconButton } from '@components/buttons';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { PackageMenu } from '../packageMenu/packageMenu';

import s from './settings.style';

type PackageId = number;

interface SettingsPopoverProps {
  id: PackageId;
  isOwner: boolean;
  onMenuVisible: (visible: boolean) => void;
  onClickOutside?(): void;
}

export const Settings = ({ id, isOwner, onMenuVisible, onClickOutside }: SettingsPopoverProps) => {
  const [wide, setWide] = useState(false);
  const [level, setLevel] = useState(0);

  const handleLevelChange = (params: { wide: boolean; level: number }) => {
    setWide(params.wide);
    setLevel(params.level);
  };

  const renderMenu = (p) => (
    <PackageMenu id={id} isOwner={isOwner} {...p} onClickOutside={onClickOutside} onLevelChange={handleLevelChange} />
  );

  return (
    <s.IconBlock>
      <BaseDropdown
        usePortal
        wide={wide}
        level={level}
        forceLeftRecalculate
        onLevelChange={handleLevelChange}
        placement="bottom-end"
        onMenuVisible={onMenuVisible}
        onClickOutside={onClickOutside}
        renderInput={(p) => <IconButton {...p} iconName="kebab" />}
        renderMenu={(p) => renderMenu(p)}
      />
    </s.IconBlock>
  );
};

export default Settings;
