import React from 'react';

import {
  Header,
  Id,
} from '../header';

import { Addition } from './addition';
import { AnnotationContent } from './annotationContent';

import s from './annotation.style';

interface AnnotationProps {
  id: Id
  rating: string;
  inProgress: boolean;
  isExpanded: boolean;
  shouldRender: boolean;
  hasError: boolean;
  body: string;
  additions?: string[];
  onCreate(id: Id): void;
  onToggle(id: Id): void;
  onRatingUpdate(data: { id: Id, value: string }): void;
}

export class Annotation extends React.PureComponent<AnnotationProps> {

  static title = 'аннотация';

  static errorTitle = 'аннотацию';

  renderAdditions() {
    const shouldRenderAdditions = this.props.additions && this.props.additions.length;
    if (!shouldRenderAdditions) {
      return null;
    }

    return (
      this.props.additions.map((item) => <Addition body={item} />)
    );
  }

  renderContent() {
    if (!this.props.shouldRender) {
      return null;
    }

    if (this.props.inProgress) {
      return null;
    }

    if (this.props.hasError) {
      return null;
    }

    if (!this.props.isExpanded) {
      return null;
    }

    return (
      <s.ContentContainer>
        <AnnotationContent>
          {this.props.body}
        </AnnotationContent>
        <AnnotationContent>
          {this.renderAdditions()}
        </AnnotationContent>
      </s.ContentContainer>
    );
  }

  render() {
    return (
      <s.Root
        id="annotation"
      >
        <Header
          title={Annotation.title}
          errorTitle={Annotation.errorTitle}
          {...this.props}
        />
        {this.renderContent()}
      </s.Root>
    );
  }

}

export default Annotation;
