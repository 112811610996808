import { RESTPath } from '@router/path.rest';
import http from '@http';

import type { Id } from '@router/path.rest';

import type { Body } from './state';

export interface MessagesDTO {
  result: Body[];
}

export function httpFetchDefaultMessages(documentId: Id): Promise<MessagesDTO> {
  const url = RESTPath.aiChat.document.defaultMessages(documentId);
  const request = http.get({
    url,
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchDefaultMessages;
