import { Map } from 'immutable';

export const MAXIMUM_SELECTED_LENGTH = 100;

type Loading = boolean;
type Counter = number;

export enum ViewType {
  table = 'table',
  list = 'list',
}

export interface Layout {
  viewType: ViewType.list | ViewType.table;
}

export type Id = number;
export type ProjectNumber = string;

interface ModifiedLinkDTO {
  id: string;
  title: string;
}

export interface ProjectDTO {
  id: Id;
  project_number: ProjectNumber;
  title: string;
  folder_count: number;
  familiarize: boolean;
  start_date: string;
  last_event_date: string;
  last_event: string;
  current_phase: string;
  modified_links: ModifiedLinkDTO[];
  source: {
    title: string;
    link: string;
  };
  author: string;
  type: string;
  branch_of_legislation: string;
  logo: string;
  is_new: boolean;
  comments_count: Counter;
  summary: string,
}

export interface BodyBlock {
  id: string;
  body: string;
}

export enum Type {
  text = 'text',
  word = 'word',
  pdf = 'pdf',
  zip = 'zip',
}

export interface File {
  type: Type.text | Type.word | Type.pdf | Type.zip;
  name: string;
  link: string;
}

interface Event {
  date: string;
  title: string;
  description: string[];
  files: File[];
}

export interface Phase {
  title: string;
  events: Event[];
}

interface PreviewDocument {
  body: BodyBlock[];
  loading: Loading;
}

interface Phases {
  items: Phase[];
  loading: Loading;
}

interface SpectractorDTO {
  user_id: number;
  name: string;
  email: string;
  avatar: string;
  status: {
    title: string;
    date: string;
  };
}

interface Spectractor {
  id: number;
  name: string;
  email: string;
  avatar: string;
  changeStatusTitle: string;
  familiarized: boolean;
}

interface Spectractors {
  items: Spectractor[];
  loading: Loading;
}

interface Preview {
  project: PreviewDocument;
  note: PreviewDocument;
  information: ProjectInformation;
  spectractors: Spectractors,
  phases: Phases;
}

type ModifiedLink = {
  id: string;
  name: string;
};

export interface ProjectInformation {
  id: Id;
  projectNumber: ProjectNumber;
  title: string;
  folderCount: number;
  startDate: string;
  lastEventDate: string;
  lastEvent: string;
  currentPhase: string;
  modifiedLinks: ModifiedLink[];
  source: {
    title: string,
    link: string,
  };
  author: string;
  type: string;
  branchOfLegislation: string;
  isNew: boolean;
}

export interface Project {
  id: Id;
  projectNumber: ProjectNumber;
  title: string;
  folderCount: number;
  lastEventDate: string;
  lastEvent: string;
  currentPhase: string;
  familiarize: boolean;
  startDate: string;
  source: {
    title: string;
    link: string;
  };
  author: string;
  type: string;
  branchOfLegislation: string;
  logo: string;
  isNew: boolean;
  preview: Preview;
  commentsCount: Counter;
  summary: string,
}

interface Projects {
  itemsList: Id[];
  itemsMap: Map<Id, Project>;
}

type PageNumber = [number, string] | number;

export interface State {
  routeType: RouteTypes;
  currentPageNumber: PageNumber;
  layout: Layout;
  selectProjects: Id[];
  loading: Loading;
  pageLoading: Loading;
  projects: Projects;
  previewId: Id;
  shouldLoadSettings: boolean;
  settingsLoading: Loading;
  totalProjects: Counter;
  newProjects: Counter;
  withoutChange: Counter;
  lastPageReached: boolean;
}

export enum RouteType {
  projects = 'projects',
  folder = 'folder',
  calendar = 'calendar',
  comments = 'comments',
}

export type RouteTypes = RouteType.projects | RouteType.folder | RouteType.calendar | RouteType.comments;

export class StateBuilder {

  private state: State;

  static createState() {
    return {
      routeType: RouteType.projects,
      currentPageNumber: [],
      layout: {
        viewType: ViewType.list,
      },
      loading: true,
      pageLoading: false,
      projects: {
        itemsList: [],
        itemsMap: Map(),
      },
      previewId: null,
      selectProjects: [],
      settingsLoading: true,
      totalProjects: 0,
      newProjects: 0,
      withoutChange: 0,
      lastPageReached: false,
    };
  }

  constructor(state: State) {
    this.state = state;
  }

  private buildProject(project: ProjectDTO): Project {
    return {
      id: project.id,
      projectNumber: project.project_number,
      title: project.title,
      folderCount: project.folder_count,
      familiarize: project.familiarize,
      startDate: project.start_date,
      lastEventDate: project.last_event_date,
      lastEvent: project.last_event,
      currentPhase: project.current_phase,
      source: project.source,
      author: project.author,
      type: project.type,
      branchOfLegislation: project.branch_of_legislation,
      logo: project.logo,
      isNew: project.is_new,
      preview: this.buildPreview(project),
      commentsCount: project.comments_count || 0,
      summary: project.summary,
    };
  }

  private buildModifiedLinks(data) {
    return data.map((item) => ({
      id: item.id,
      name: item.title,
    }));
  }

  private buildInformation(project: ProjectDTO): ProjectInformation {
    return {
      id: project.id,
      projectNumber: project.project_number,
      title: project.title,
      folderCount: project.folder_count,
      startDate: project.start_date,
      lastEventDate: project.last_event_date,
      lastEvent: project.last_event,
      currentPhase: project.current_phase,
      modifiedLinks: this.buildModifiedLinks(project.modified_links),
      source: project.source,
      author: project.author,
      type: project.type,
      branchOfLegislation: project.branch_of_legislation,
      isNew: project.is_new,
    };
  }

  private buildPreview(project: ProjectDTO): Preview {
    return {
      project: {
        body: [],
        loading: true,
      },
      note: {
        body: [],
        loading: true,
      },
      information: {
        ...this.buildInformation(project),
      },
      phases: {
        items: [],
        loading: true,
      },
      spectractors: {
        items: [],
        loading: true,
      },
    };
  }

  public buildPreviewProject(id: Id, body) {
    const item = this.state.projects.itemsMap.get(id);
    item.preview = {
      ...item.preview,
      project: {
        body,
        loading: false,
      },
    };

    const itemsMap = this.state.projects.itemsMap.set(id, item);

    this.state = {
      ...this.state,
      projects: {
        ...this.state.projects,
        itemsMap,
      },
    };
  }

  public setLastPageReached() {
    this.state = {
      ...this.state,
      lastPageReached: true,
    };
  }

  public removeProject(id: Id) {
    const itemsList = this.state.projects.itemsList.filter((item) => item !== id);
    const itemsMap = this.state.projects.itemsMap.delete(id);

    this.state = {
      ...this.state,
      projects: {
        itemsList,
        itemsMap,
      },
    };
  }

  public updateFoldersCount(params) {
    let itemsMap;
    // eslint-disable-next-line
    for (const key in params) {
      const id = Number(key);
      const item = this.state.projects.itemsMap.get(id);
      item.folderCount = Number(params[key]);
      itemsMap = this.state.projects.itemsMap.set(id, item);
    }

    this.state = {
      ...this.state,
      projects: {
        ...this.state.projects,
        itemsMap,
      },
    };
  }

  public toggleFamiliarize(id: Id) {
    const item = this.state.projects.itemsMap.get(id);
    item.familiarize = !item.familiarize;

    const itemsMap = this.state.projects.itemsMap.set(id, item);

    this.state = {
      ...this.state,
      projects: {
        ...this.state.projects,
        itemsMap,
      },
    };
  }

  public increaseFolderCount(id: Id) {
    const item = this.state.projects.itemsMap.get(id);
    item.folderCount += 1;

    const itemsMap = this.state.projects.itemsMap.set(id, item);

    this.state = {
      ...this.state,
      projects: {
        ...this.state.projects,
        itemsMap,
      },
    };
  }

  public increaseCommentsCount(id: Id) {
    const item = this.state.projects.itemsMap.get(id);
    item.commentsCount += 1;
    const itemsMap = this.state.projects.itemsMap.set(id, item);

    this.state = {
      ...this.state,
      projects: {
        ...this.state.projects,
        itemsMap,
      },
    };
  }

  public decreaseFolderCount(id: Id) {
    const item = this.state.projects.itemsMap.get(id);
    item.folderCount -= 1;

    const itemsMap = this.state.projects.itemsMap.set(id, item);

    this.state = {
      ...this.state,
      projects: {
        ...this.state.projects,
        itemsMap,
      },
    };
  }

  public checkProject(id: Id) {
    const selectProjects = this.state.selectProjects.concat(id);
    this.state = {
      ...this.state,
      selectProjects,
    };
  }

  public uncheckProject(id: Id) {
    const selectProjects = this.state.selectProjects.filter((currentId) => currentId !== id);
    this.state = {
      ...this.state,
      selectProjects,
    };
  }

  public checkProjects(selectProjects: Id[]) {
    this.state = {
      ...this.state,
      selectProjects,
    };
  }

  public uncheckProjects() {
    this.state = {
      ...this.state,
      selectProjects: [],
    };
  }

  public buildPreviewNote(id: Id, body) {
    const item = this.state.projects.itemsMap.get(id);
    item.preview = {
      ...item.preview,
      note: {
        body,
        loading: false,
      },
    };

    const itemsMap = this.state.projects.itemsMap.set(id, item);

    this.state = {
      ...this.state,
      projects: {
        ...this.state.projects,
        itemsMap,
      },
    };
  }

  public buildPreviewPhases(id: Id, items) {
    const item = this.state.projects.itemsMap.get(id);
    item.preview = {
      ...item.preview,
      phases: {
        items,
        loading: false,
      },
    };

    const itemsMap = this.state.projects.itemsMap.set(id, item);

    this.state = {
      ...this.state,
      projects: {
        ...this.state.projects,
        itemsMap,
      },
    };
  }

  public buildSpectractor(item) {
    const familiarized = item.status.title.toLowerCase() === 'ознакомлен';

    return {
      id: item.user_id,
      name: item.name,
      email: item.email,
      avatar: item.avatar,
      familiarized,
      changeStatusTitle: item.status.date,
    };
  }

  public buildPreviewSpectractors(id: Id, itemsDTO: SpectractorDTO[]) {
    const item = this.state.projects.itemsMap.get(id);

    const items = itemsDTO.map((currentItem) => this.buildSpectractor(currentItem));
    item.preview = {
      ...item.preview,
      spectractors: {
        items,
        loading: false,
      },
    };

    const itemsMap = this.state.projects.itemsMap.set(id, item);

    this.state = {
      ...this.state,
      projects: {
        ...this.state.projects,
        itemsMap,
      },
    };
  }

  public addProjects(projects: ProjectDTO[]): void {
    projects.forEach((item) => {
      const project = this.buildProject(item);
      const itemsList = [...this.state.projects.itemsList, project.id];
      const itemsMap = this.state.projects.itemsMap.set(project.id, project);

      this.state = {
        ...this.state,
        projects: {
          itemsList,
          itemsMap,
        },
      };
    });
  }

  public setCurrentPageNumber(pageNumber: PageNumber) {
    this.state = {
      ...this.state,
      currentPageNumber: pageNumber,
    };
  }

  public setTotalProjects(counter: Counter) {
    this.state = {
      ...this.state,
      totalProjects: counter,
    };
  }

  public setNewProjects(counter: Counter) {
    this.state = {
      ...this.state,
      newProjects: counter,
    };
  }

  public setWithoutChangeCounter(counter: Counter) {
    this.state = {
      ...this.state,
      withoutChange: counter,
    };
  }

  public setLoading(loading: Loading) {
    this.state = {
      ...this.state,
      loading,
    };
  }

  public setPageLoading(pageLoading: Loading) {
    this.state = {
      ...this.state,
      pageLoading,
    };
  }

  public setSettings(layout: Layout) {
    this.state = {
      ...this.state,
      layout,
    };
  }

  public setSettingsLoading(settingsLoading: Loading) {
    this.state = {
      ...this.state,
      settingsLoading,
    };
  }

  public setPreview(projectId: Id) {
    this.state = {
      ...this.state,
      previewId: projectId,
    };
  }

  public setRouteType(routeType: RouteTypes) {
    this.state = {
      ...this.state,
      routeType,
    };
  }

  public getState() {
    return this.state;
  }

}

export default StateBuilder;
