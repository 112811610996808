import { Dispatch } from 'redux';

import { ProjectId } from './state';
import { Rating } from './annotation';

import { httpUpdateAnnotationRating } from './updateAnnotationRating.http';
import { updateAnnotationRating } from './updateAnnotationRating';

export interface RequestParams {
  projectId: ProjectId;
  value: Rating;
}

export class UpdateAnnotationRatingThunk {

  getState;

  private readonly dispatch: Dispatch;

  private requestParams: RequestParams;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: RequestParams) {
    const {
      projectId,
      value,
    } = params;

    if (!projectId) {
      return;
    }

    const state = this.getState().regulation.annotations;
    const stateAnnotation = state.items.get(projectId);
    if (!stateAnnotation) {
      return;
    }

    const shouldReset = stateAnnotation.rating === value;
    const newValue = shouldReset ? null : value;

    this.requestParams = {
      projectId,
      value: newValue,
    };

    this.dispatchRatingUpdate();
    this.ratingUpdate();
  }

  private async ratingUpdate() {
    try {
      await httpUpdateAnnotationRating(this.requestParams);

    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchRatingUpdate() {
    this.dispatch(updateAnnotationRating.createAction(this.requestParams));
  }

}

export function updateAnnotationRatingThunk(dispatch, params) {
  const thunk = new UpdateAnnotationRatingThunk(dispatch);

  dispatch((_, getState) => {
    thunk.getState = getState;
  });

  thunk.invoke(params);
}

export default UpdateAnnotationRatingThunk;
