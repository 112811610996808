import { addMessageReduce } from './addMessage.reduce';

import type { ActionData } from './addMessage.reduce';

const actionType = 'CASELOOK/DOCUMENT/AI_CHAT/ADD_MESSAGE';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const addMessage = {
  actionType,
  createAction,
  reduce: addMessageReduce,
};

export default addMessage;
