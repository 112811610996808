import React from 'react';

import { IconButton } from '@components/buttons';

import s from './messageControls.style';

type Vote = 'good' | 'bad';

interface MessageControlsProps {
  onCopyToBuffer(): void;
  onVote(vote: Vote): void;
  vote: Vote;
}

export const MessageControls = ({
  vote,
  onVote,
  onCopyToBuffer,
}: MessageControlsProps) => {

  const onClickGood = () => onVote('good');

  const onClickBad = () => onVote('bad');

  const goodIcon = vote === 'good' ? 'thumbUpActive' : 'thumbUp';
  const badIcon = vote === 'bad' ? 'thumbDownActive' : 'thumbDown';

  const isGoodActive = vote === 'good';

  return (
    <s.Root>
      <s.Button>
        <IconButton onClick={onCopyToBuffer} iconName="toolbarCopy" />
      </s.Button>
      <s.Button isActive={isGoodActive}>
        <IconButton onClick={onClickGood} iconName={goodIcon} />
      </s.Button>
      <s.Button>
        <IconButton onClick={onClickBad} iconName={badIcon} />
      </s.Button>
    </s.Root>
  );
};

export default MessageControls;
