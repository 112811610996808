import { connect } from 'react-redux';

import { Tabs } from '@pages/document/sidebar/compoents/tabs/tabs';

import { setActiveTab } from './redux/setActiveTab';
import { prepareTabs } from './tabUtils';

const mapStateToProps = (state) => {
  const { activeTabId } = state.lawDocument.layout.sidebar;
  const { tabs } = prepareTabs();

  return {
    tabs,
    additionTabs: [],
    activeId: activeTabId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClick(tabId) {
    dispatch(setActiveTab.createAction({ tabId }));
  },
});

export const TabsConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Tabs);

export default TabsConnect;
