import {
  css,
  styled,
} from '@components/styled';

interface Props {
  theme: any;
  height: number;
  isActive: boolean;
}

const MAX_HEIGHT = 384;

const style: any = {};

style.Root = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 100%;
  padding: 0 16px 16px;
  position: relative;
`;

style.Textarea = styled.textarea`
  display: block;

  height: ${(props: Props) => props.height}px;
  max-height: ${MAX_HEIGHT}px;
  width: 100%;

  padding: 16px 48px;
  border-color: ${(props: Props) => props.theme.colors.silver};
  border-radius: 24px;

  background: ${(props: Props) => props.theme.white};

  outline: 0;
  resize: none;

  overflow-y: hidden;

  &[disabled] {
    background-color: ${(props: Props) => props.theme.colors.white};
    cursor: default;
  }

  ${(props: Props) => props.height > MAX_HEIGHT && css`
    overflow-y: auto;
  `}
`;

style.Menu = styled.div`
  position: absolute;
  left: 28px;
  bottom: 24px;
`;

style.Action = styled.div`
  position: absolute;
  right: 28px;
  bottom: 24px;
`;

style.Label = styled.label`
  position: absolute;
  top: 16px;
  left: 65px;
  color: ${(props:Props) => props.theme.colors.stone};
  transform: translate(0, 1px) scale(1);
  transform-origin: top left;
  transition: all 150ms cubic-bezier(0.23, 1, 0.32, 1);
  pointer-events: none;

  &[disabled] {
    color: ${(props: Props) => props.theme.colors.disabled};
  }
`;

style.TextMeasureWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 16px;
  visibility: hidden;
`;

style.TextMeasure = styled.div`
  min-height: 48px;
  padding: 16px 48px;
  line-height: normal;
  white-space: pre-line;
  word-wrap: break-word;
`;

export default style;
