import React from 'react';

import { buildDangerousHTML } from '@utils/utils';

import s from './addition.style';

interface Props {
  body: string;
}

export const Addition: React.FC<Props> = ({ body }) => (
  <s.Root
    dangerouslySetInnerHTML={buildDangerousHTML(body)}
  />
);

export default Addition;
