import React from 'react';

import { Loader } from '@components/loader';

import { Owner } from './message';

import { MessageConnect } from './message.connect';
import { MessageControlsConnect } from './messageControls.connect';

import { Loader as MessageLoader } from './loader';

import type { MessageProps } from './message';

import s from './chatView.style';

export interface ChatViewProps {
  documentId: number;
  items: MessageProps[];
  answerLoading: boolean;
  loading: boolean;
}

export const domId = 'caselook-aichat-chat-view';

export const ChatView = (props: ChatViewProps) => {

  const rootRef = React.useRef(null);

  React.useEffect(() => {
    const { current } = rootRef;
    current.scrollTop = current.scrollHeight;
  }, [props.items.length]);

  const renderItem = (item: MessageProps) => {
    let controls = null;
    const isAnswer = item.type === Owner.bot;
    const isSystem = typeof (item.id) !== 'number';
    if (isAnswer && !isSystem) {
      controls = <MessageControlsConnect id={item.id} parentId={props.documentId} />;
    }

    return (
      <MessageConnect
        id={item.id}
        key={item.id}
        body={item.body}
        controls={controls}
      />
    );
  };

  const renderLoader = () => (
    <s.Loader>
      <Loader size={32} />
    </s.Loader>
  );

  const renderContent = () => {
    if (props.loading) {
      return renderLoader();
    }

    return props.items.map(renderItem);
  };

  const renderMessageLoader = () => {
    if (!props.answerLoading) {
      return null;
    }

    return <MessageLoader title="Анализирую судебную практику..." />;
  };

  return (
    <s.Root
      ref={rootRef}
    >
      <s.Content id={domId}>
        {renderContent()}
      </s.Content>
      {renderMessageLoader()}
    </s.Root>
  );
};
