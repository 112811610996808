import {
  StateBuilder,
  Rating,
} from './state';

import type { Id } from './state';

export type ActionData = {
  id: Id;
  rating: Rating.good | Rating.bad;
};

export interface Action {
  type: string;
  data: ActionData;
}

export const voteMessageReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state);

  newState.updateRating(action.data);

  return {
    ...newState.getState(),
  };
};

export default voteMessageReduce;
