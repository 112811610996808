import React, { useState } from 'react';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { IconButton } from '@components/buttons';

import { GlobalMenuConnect as GlobalMenu } from './globalMenu.connect';

import s from './globalSettings.style';

interface GlobalSettingsProps {
  disabled: boolean;
}

export const GlobalSettings: React.FC<GlobalSettingsProps> = (props: GlobalSettingsProps) => {
  const [wide, setWide] = useState(false);
  const [level, setLevel] = useState(0);

  const handleLevelChange = (params: { wide: boolean; level: number }) => {
    setWide(params.wide);
    setLevel(params.level);
  };

  const renderMenu = (p) => (
    <GlobalMenu {...p} onLevelChange={handleLevelChange} />
  );

  return (
    <s.IconBlock>
      <BaseDropdown
        wide={wide}
        level={level}
        disabled={props.disabled}
        onLevelChange={handleLevelChange}
        placement="bottom-end"
        renderInput={(p) => <IconButton {...p} disabled={props.disabled} iconName="kebab" />}
        renderMenu={(p) => renderMenu(p)}
      />
    </s.IconBlock>
  );
};

export default GlobalSettings;
