import { voteMessageReduce } from './voteMessage.reduce';

import type { ActionData } from './voteMessage.reduce';

const actionType = 'CASELOOK/DOCUMENT/AI_CHAT/VOTE_MESSAGE';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const voteMessage = {
  actionType,
  createAction,
  reduce: voteMessageReduce,
};

export default voteMessage;
