import React from 'react';

import s from './counter.style';

interface CounterProps {
  counter: number;
}

export const Counter: React.FC<CounterProps> = (props) => {
  const title = `НАЙДЕНО ${props.counter}`;

  return (
    <s.Root>
      {title}
    </s.Root>
  );
};

export default Counter;
