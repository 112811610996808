import { connect } from 'react-redux';

import { setActiveTab } from './redux/setActiveTab';
import { setActiveReasonToggle } from './redux/setActiveReasonToggle';

import {
  tabTypes,
  prepareTabs,
  sendAmplitude,
} from './tabUtils';

import { Tabs } from './compoents/tabs/tabs';

const mapStateToProps = (state) => {
  const { activeTabId } = state.document.layout.sidebar;
  const { tabs, additionTabs } = prepareTabs(state);

  return {
    tabs,
    additionTitle: 'Еще',
    additionTabs,
    activeId: activeTabId,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClick(tabId) {
    dispatch(setActiveTab.createAction({ tabId }));
    if (tabId === tabTypes.reason.id) {
      dispatch(setActiveReasonToggle.createAction());
    }

    sendAmplitude(tabId);
  },
});

export const TabsConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Tabs);

export default TabsConnect;
