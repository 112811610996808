import { Dispatch } from 'redux';

import { notifierManager } from '@components/notification';

import { Id } from '@router/path.rest';

import { Rating } from './state';

import { httpVoteMessage } from './voteMessage.http';
import { voteMessage } from './voteMessage';

export type Vote = 'good' | 'bad';

export interface VoteParams {
  id: Id;
  parentId: Id;
  rating: Rating;
}

export class VoteMessageThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: VoteParams) {
    const state = this.getState();
    const message = state.aiChat.messages.itemsMap.get(params.id);
    const isEqual = params.rating === message.rating;

    const rating = isEqual ? null : params.rating;
    const requestParams = {
      ...params,
      rating,
    };

    if (!isEqual) {
      this.sendNotification();
    }

    this.dispatchVoteMessage(requestParams);
    await this.sendVote(requestParams);
  }

  private async sendVote(params) {
    const requestParams = {
      documentId: params.parentId,
      messageId: params.id,
      rating: params.rating,
    };

    try {
      await httpVoteMessage(requestParams);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchVoteMessage(params) {
    this.dispatch(voteMessage.createAction(params));
  }

  private sendNotification() {
    const id = 'CASELOOK_AI_CHAT_VOTING_THANKS';
    const message = 'Спасибо за вашу оценку';
    notifierManager.done({ id, message });
    setTimeout(() => {
      notifierManager.remove({ id });
    }, 2000);
  }

}

export function voteMessageThunk(dispatch, params) {
  const thunk = new VoteMessageThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default VoteMessageThunk;
