import React from 'react';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { IconButton } from '@components/buttons';

import { ItemMenuConnect as ItemMenu } from './itemMenu.connect';

type ParagraphId = number;

interface SettingsPopoverProps {
  paragraphId: ParagraphId;
  onMenuVisible: (visible: boolean) => void;
}

export const ItemSettings = ({ paragraphId, onMenuVisible }: SettingsPopoverProps) => {
  const renderMenu = (p) => (
    <ItemMenu paragraphId={paragraphId} {...p} />
  );

  return (
    <BaseDropdown
      placement="bottom-end"
      onMenuVisible={onMenuVisible}
      forceMenuVisible
      renderInput={(p) => <IconButton {...p} iconName="kebab" />}
      renderMenu={(p) => renderMenu(p)}
    />
  );
};

export default ItemSettings;
