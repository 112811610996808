import React from 'react';

import {
  CloseButton,
  OpenButton,
} from '@components/buttons';

import { halfTooltipWidth } from '@pages/document/workspace/workspace';

import { TooltipRelationWithClickConnect }
  from '@pages/searchResult/documents/preview/components/tooltip/tooltipRelations.connect';

import { Bookmarks } from '@layouts/bookmarks/components/bookmarks';

import { DocumentTab } from '@pages/searchResult/documents/preview/documentTab';
import { Attributes as AttributesTab } from '@pages/document/sidebar/attributes';
import { AIChatConnect as AIChatTab } from '@pages/searchResult/documents/preview/aiChat.connect';
import { ResolutionTab } from '@pages/searchResult/documents/preview/resolutionTab';
import { ChronologyTabConnect as ChronologyTab } from '@pages/searchResult/documents/preview/chronologyTab.connect';
import { SimilarTabConnect as SimilarTab } from '@pages/searchResult/documents/preview/similarTab.connect';

import s from '@pages/searchResult/documents/preview/documentPreview.style';

import { AddPackageConnect } from './addPackage.connect';
// Todo: [sharing] Вернуть с функционалом открытия шаренных документов
// import { SharedLinkCopyConnect } from './sharedLinkCopy.connect';
import { DownloadConnect } from './download.connect';
import { RequisitesCopyConnect } from './requisitesCopy.connect';

export interface DocumentsProps {
  source: string;
  sourceId: string;
  documentId?: number;
  baseHref: string;
  data: any;
  onFetch: () => void;
  documentLoaded: boolean;
  attributesLoaded: boolean;
  shouldRenderChat: boolean;
  onClose: () => void;
  onCommentaryChange(commentary: string): void;
  onSaveMarkers(entities): void;
}

interface HeaderProps {
  baseHref: string;
  onClose: () => void;
  sourceId: string;
  documentId: number;
  title: string;
}

const Header = (props: HeaderProps) => (
  <s.Header>
    <s.HeaderCloseButton>
      <CloseButton
        onClick={props.onClose}
      />
    </s.HeaderCloseButton>
    <s.HeaderTitle>{props.title}</s.HeaderTitle>
    <s.Tools>
      <s.Tool>
        <Bookmarks />
      </s.Tool>
      <s.Tool>
        <AddPackageConnect
          id={props.documentId}
          usePortal={false}
        />
      </s.Tool>
      {/* Todo: [sharing] Вернуть с функционалом открытия шаренных документов */}
      {/* <s.Tool> */}
      {/*  <SharedLinkCopyConnect */}
      {/*    id={props.documentId} */}
      {/*    usePortal={false} */}
      {/*  /> */}
      {/* </s.Tool> */}
      <s.Tool>
        <DownloadConnect
          id={props.documentId}
          usePortal={false}
        />
      </s.Tool>
      <s.Tool marginRight={12}>
        <RequisitesCopyConnect
          usePortal={false}
        />
      </s.Tool>
      <s.Tool marginRight={4}>
        <OpenButton
          href={props.baseHref}
        />
      </s.Tool>
    </s.Tools>
  </s.Header>
);

type ItemId = string;

interface TabBarItemProps {
  onClick: (id: ItemId) => void;
  title: string | React.Component;
  activeId: ItemId;
  id: ItemId;
}

const TabBarItem = (props: TabBarItemProps) => {
  const handleClick = () => {
    props.onClick(props.id);
  };

  const isActive = props.activeId === props.id;

  return <s.TabItem active={isActive} onClick={handleClick}>{props.title}</s.TabItem>;
};

interface TabBarProps {
  activeId: ItemId;
  tabs: any[];
  onTabClick: (id: ItemId) => void;
}

const TabBar = (props: TabBarProps) => {
  const renderTabs = () => props.tabs.map((tab) => (
    <TabBarItem
      activeId={props.activeId}
      key={tab.id}
      id={tab.id}
      title={tab.title}
      onClick={props.onTabClick}
    />
  ));

  return (
    <s.TabBar>
      {renderTabs()}
    </s.TabBar>
  );
};

export class DocumentPreview extends React.PureComponent<DocumentsProps, any> {

  protected tabs = {
    document: {
      id: 'document',
      title: 'Документ',
      component: DocumentTab,
    },
    resolution: {
      id: 'resolution',
      title: 'Резолютивная часть',
      component: ResolutionTab,
    },
    attributes: {
      id: 'attributes',
      title: 'Атрибуты',
      component: AttributesTab,
    },
    aichat: {
      id: 'aichat',
      title: 'AI-ассистент',
      component: AIChatTab,
    },
    chronology: {
      id: 'chronology',
      title: 'Хронология',
      component: ChronologyTab,
    },
    similar: {
      id: 'similar',
      // eslint-disable-next-line
      title: 'Похожие',
      component: SimilarTab,
    },
  };

  chronologyRef: React.RefObject<HTMLDivElement> = React.createRef();

  similarRef: React.RefObject<HTMLDivElement> = React.createRef();

  state = {
    activeTabId: this.tabs.document.id,
    tooltipIdRelation: '',
    tooltipAlign: 'center',
    tooltipOffsets: {},
    tooltipData: {},
    tooltipShow: false,
  };

  componentDidMount() {
    const loaded = this.props.documentLoaded && this.props.attributesLoaded;
    if (!loaded) {
      this.props.onFetch();
    }
  }

  componentDidUpdate(prevProps: Readonly<DocumentsProps>) {
    const isNew = this.props.documentId !== prevProps.documentId;
    if (isNew) {
      this.props.onFetch();
    }
  }

  componentWillUnmount() {
    this.props.onClose();
  }

  handleChangeActiveTab = (tabId: ItemId) => {
    this.setActiveTab(tabId);
  };

  setActiveTab = (tabId) => {
    this.setState({
      activeTabId: tabId,
    });
  };

  handleOnClick = (event) => {
    event.stopPropagation();

    const tagNameInLowerCase = event.target.tagName.toLowerCase();
    const elementAttribute = event.target.getAttribute('data-id-relation');
    const parentElementAttribute = event.target.parentNode.getAttribute('data-id-relation');
    const isRelationTag =
      // eslint-disable-next-line no-mixed-operators
      tagNameInLowerCase === 'a' && elementAttribute ||
      // eslint-disable-next-line no-mixed-operators
      tagNameInLowerCase === 'mark' && parentElementAttribute;

    const DomRect = event.target.getBoundingClientRect();
    // eslint-disable-next-line max-len
    const isSmallWidthWindow = window.innerWidth - (halfTooltipWidth + DomRect.left + DomRect.width / 2) < halfTooltipWidth;

    if (!isRelationTag) {
      return;
    }

    event.preventDefault();
    const relationId = elementAttribute || parentElementAttribute;
    const tooltipOffsets = {
      top: DomRect.top,
      right: window.innerWidth - DomRect.left - DomRect.width,
      width: DomRect.width,
    };

    if (!this.state.tooltipShow) {
      this.setState({
        tooltipIdRelation: relationId,
        tooltipAlign: isSmallWidthWindow ? 'left' : 'center',
        tooltipOffsets: { ...tooltipOffsets },
        tooltipShow: true,
      });
    } else {
      this.setState({
        tooltipIdRelation: '',
        tooltipAlign: 'center',
        tooltipOffsets: {},
        tooltipData: {},
        tooltipShow: false,
      });
    }
  };

  hideTooltip = () => {
    if (this.state.tooltipShow) {
      this.setState({
        tooltipIdRelation: '',
        tooltipAlign: 'center',
        tooltipOffsets: {},
        tooltipData: {},
        tooltipShow: false,
      });
    }
  };

  renderTabBar() {
    const initialTabs = { ...this.tabs };
    if (!this.props.shouldRenderChat) {
      delete initialTabs.aichat;
    }

    const tabs = Object.values(initialTabs);

    return (
      <TabBar
        tabs={tabs}
        activeId={this.state.activeTabId}
        onTabClick={this.handleChangeActiveTab}
      />
    );
  }

  renderTooltipRelationContainer() {
    if (!this.state.tooltipShow) {
      return null;
    }

    return (
      <TooltipRelationWithClickConnect
        tooltipIdRelation={this.state.tooltipIdRelation}
        tooltipAlign={this.state.tooltipAlign}
        tooltipOffsets={this.state.tooltipOffsets}
        tooltipData={this.state.tooltipData}
        onClickOutside={this.hideTooltip}
        {...this.props}
      />
    );
  }

  renderActiveTab() {
    const Component = this.tabs[this.state.activeTabId].component;

    const isDocument =
      this.state.activeTabId === this.tabs.document.id ||
      this.state.activeTabId === this.tabs.resolution.id;

    if (isDocument) {
      return (
        <s.Workspace
          onClick={this.handleOnClick}
        >
          {this.renderTooltipRelationContainer()}
          <Component
            onSaveMarkers={this.props.onSaveMarkers}
            body={this.props.data[this.state.activeTabId].body}
            title={this.props.data[this.state.activeTabId].title}
            department={this.props.data[this.state.activeTabId].department}
            highlightIds={this.props.data[this.state.activeTabId].highlightIds}
            reasonIds={this.props.data[this.state.activeTabId].reasonsIds}
            activeReason={this.props.data[this.state.activeTabId].activeReason}
            loadingReasons={this.props.data[this.state.activeTabId].loadingReasons}
            loadingSuccess={this.props.data[this.state.activeTabId].loadingSuccess}
            bookmarkTitle={this.props.data.title}
            source={this.props.source}
            sourceId={this.props.sourceId}
            documentId={this.props.documentId}
            loading={!this.props.documentLoaded || !this.props.attributesLoaded}
          />
        </s.Workspace>
      );
    }

    const isChronology = this.state.activeTabId === this.tabs.chronology.id;
    if (isChronology) {
      return (
        <s.Workspace
          ref={this.chronologyRef}
        >
          <Component
            wrapperRef={this.chronologyRef}
            baseHref={this.props.baseHref}
            source={this.props.source}
            sourceId={this.props.sourceId}
            documentId={this.props.documentId}
          />
        </s.Workspace>
      );
    }

    const isSimilar = this.state.activeTabId === this.tabs.similar.id;
    if (isSimilar) {
      return (
        <s.Workspace
          ref={this.similarRef}
        >
          <Component
            wrapperRef={this.similarRef}
            baseHref={this.props.baseHref}
            source={this.props.source}
            sourceId={this.props.sourceId}
            documentId={this.props.documentId}
          />
        </s.Workspace>
      );
    }

    const isChat = this.state.activeTabId === this.tabs.aichat.id;
    if (isChat) {
      return (
        <s.Workspace>
          <s.Ai>
            <Component
              id={this.props.documentId}
            />
          </s.Ai>
        </s.Workspace>
      );
    }

    return (
      <s.Workspace>
        <Component
          data={{
            attributes: this.props.data[this.state.activeTabId],
            commentary: this.props.data[this.state.activeTabId].comment,
          }}
          onCommentaryChange={this.props.onCommentaryChange}
          loading={!this.props.attributesLoaded}
        />
      </s.Workspace>
    );
  }

  render() {
    return (
      <>
        <s.Root>
          <Header
            baseHref={this.props.baseHref}
            sourceId={this.props.sourceId}
            documentId={this.props.documentId}
            title={this.props.data.title}
            onClose={this.props.onClose}
          />
          {this.renderTabBar()}
          {this.renderActiveTab()}
        </s.Root>
        <s.Background />
      </>
    );
  }

}

export default DocumentPreview;
