/* eslint-disable */
import React from 'react';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from '@utils/userpilot';

import { WithHeaderNotification } from '@components/withHeaderNotification/withHeaderNotification';

import { Header } from './header/header';
import { WorkspaceConnect } from './workspace/workspace.connect';
import { SidebarConnect } from './sidebar/sidebar.connect';

import s from './document.style';

export class Document extends React.PureComponent {

  componentDidMount() {
    Userpilot.reload();
    sendAmplitudeData('document view');
    Userpilot.track('document view');
    this.props.onFetchDocument();
    if (this.props.onBootstrap) {
      this.props.onBootstrap();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.documentId !== this.props.documentId) {
      this.props.onFetchDocument();
    }
  }

  render() {
    return (
      <WithHeaderNotification>
        <s.Root>
          <Header
            isReal={this.props.isReal}
            isAuthorPackage={this.props.isAuthorPackage}
            isChronologyDocument={this.props.isChronologyDocument}
            isSimilarDocument={this.props.isSimilarDocument}
          />
          <s.Content>
            <s.DocumentRoot
              id="document-view-container"
            >
              <WorkspaceConnect {...this.props} />
            </s.DocumentRoot>
            <SidebarConnect
              isAuthorPackage={this.props.isAuthorPackage}
              isChronologyDocument={this.props.isChronologyDocument}
              isSimilarDocument={this.props.isSimilarDocument}
            />
          </s.Content>
        </s.Root>
      </WithHeaderNotification>
    );
  }

}

export default Document;
