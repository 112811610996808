import React from 'react';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { Control } from './control';
import { Layout } from './layout';

import s from '../block.style';

export interface BlockProps {
  title: string;
  selectAllTitle: string;
  counter: string;
  isDefault: boolean;
  disabled: boolean;
  data: any;
  onUpdate(data): void;
  onFetchData(query: string, page: number): Promise<any>;
  withSearch?: boolean;
  Addition?: React.FC<any>;
}

export class Block extends React.PureComponent<BlockProps> {

  static defaultProps = {
    withSearch: true,
  };

  state = {
    visible: false,
  };

  handleVisible = (visible) => {
    this.setState(() => ({
      visible,
    }));
  };

  render() {
    return (
      <s.Root>
        <BaseDropdown
          usePortal
          placement="bottom-start"
          disabled={this.props.disabled}
          onMenuVisible={this.handleVisible}
          renderInput={(p) => (
            <Control
              isActive={this.state.visible}
              title={this.props.title}
              counter={this.props.counter}
              isDefault={this.props.isDefault}
              {...p}
            />
          )}
          renderMenu={(p) => (
            <Layout
              data={this.props.data}
              selectAllTitle={this.props.selectAllTitle}
              isDefault={this.props.isDefault}
              withSearch={this.props.withSearch}
              onUpdate={this.props.onUpdate}
              onFetchData={this.props.onFetchData}
              Addition={this.props.Addition}
              {...p}
            />
          )}
        />
      </s.Root>
    );
  }

}

export default Block;
