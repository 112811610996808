import styled from 'styled-components';

interface Props {
  theme: any;
}

const style: any = {};

style.Root = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: ${(props: Props) => props.theme.colors.white};
`;

export default style;
