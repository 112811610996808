import React from 'react';

import http from '@http';

import { notifierManager } from '@components/notification';

import { MenuItem } from '@pages/searchResult/documents/components/settings/menuItem';

import { setClipboard } from './messageControls.connect';
import { domId } from './chatView';

import s from './menu.style';

interface Props {
  documentId: number;
  onClickOutside?(): void;
  closePopover: () => void;
}

export function sendNotification() {
  const id = 'CASELOOK_AI_CHAT_IS_COPIED';
  const message = 'Текст переписки скопирован';
  notifierManager.done({ id, message });
  setTimeout(() => {
    notifierManager.remove({ id });
  }, 2000);
}

export const Menu = (props: Props) => {

  const copySettings = {
    title: 'Скопировать текст переписки',
    onClick: () => {
      const element = document.getElementById(domId).innerText;
      setClipboard(element);
      sendNotification();
      props.closePopover();
    },
  };

  const url = `/document/${props.documentId}/chat/download/docx`;
  const downloadSettings = {
    title: 'Скачать текст переписки',
    onClick: () => {
      window.open(`${window.location.origin}${url}`);
      props.closePopover();

      const request = http.get({
        url,
      });

      return http.fetch(request)
        .then(() => {
          props.closePopover();

          return {
            onClick: () => window.open(`${window.location.origin}${url}`),
          };
        })
        .catch(http.handlePaymentRequired);
    },
  };

  return (
    <s.Root>
      <MenuItem
        title={copySettings.title}
        onClick={copySettings.onClick}
      />
      <MenuItem
        title={downloadSettings.title}
        onClick={downloadSettings.onClick}
      />
    </s.Root>
  );
};

export default Menu;
