import styled from 'styled-components';

import { theme } from '@components/styled';

const style: any = {};

style.Root = styled.article`
  width: 384px;

  border-left: solid 1px ${theme.colors.borderColor};
  background-color: ${theme.colors.backgroundGrey};

  z-index: 4;
`;

style.Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 112px;
`;

style.Header = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 100%;
`;

style.Content = styled.div`
  position: relative;
  overflow-y: auto;
  height: 100%;
`;

export default style;
