import styled from 'styled-components';

const style: any = {};

interface Props {
  theme: any;
}

style.Root = styled.div`
  border-bottom: 1px solid ${(props: Props) => props.theme.colors.borderColor};
`;

export default style;
