import React from 'react';

import {
  Icon,
  iconsPath,
} from '@components/icons';

import { Loader } from '@components/loader';

import { Rating } from '@components/rating';

import { Button } from '@pages/searchResult/components/button/button';

import s from './header.style';

export type Id = number;

interface AnnotationProps {
  id: Id;
  errorTitle: string;
  title: string;
  rating: string;
  inProgress: boolean;
  isExpanded: boolean;
  shouldRender: boolean;
  hasError: boolean;
  onCreate(id: Id): void;
  onToggle(id: Id): void;
  onRatingUpdate(data: { id: Id, value: string }): void;
}

export class Header extends React.PureComponent<AnnotationProps> {

  handleToggle = () => {
    if (this.props.inProgress) {
      return;
    }

    this.props.onToggle(this.props.id);
  };

  handleCreate = () => {
    this.props.onCreate(this.props.id);
  };

  handleRatingUpdate = (value) => {
    this.props.onRatingUpdate({ id: this.props.id, value });
  };

  renderBetaIcon() {
    const crossPath = iconsPath.get('betaCircle');

    return (
      <s.BetaIcon>
        <Icon path={crossPath} color="#ff6400" />
      </s.BetaIcon>
    );
  }

  renderArrowRight() {
    const transform = 'translate(12.500000, 12.000000) rotate(-90.000000) translate(-12.500000, -12.000000) ';
    const points = iconsPath.get('arrowRight');
    let color = '#4183d7';

    const disabled = !!this.props.inProgress || !this.props.shouldRender || this.props.hasError;
    if (disabled) {
      color = '#dfe2e6';
    }

    return (
      <s.ArrowIcon
        onClick={this.handleToggle}
        disabled={disabled}
      >
        <Icon
          points={points}
          transform={transform}
          color={color}
        />
      </s.ArrowIcon>
    );
  }

  renderArrowDown() {
    const points = iconsPath.get('arrowDown');

    return (
      <s.ArrowIcon
        onClick={this.handleToggle}
      >
        <Icon
          points={points}
          color="#4183d7"
        />
      </s.ArrowIcon>
    );
  }

  renderArrowIcon() {
    if (this.props.isExpanded) {
      return this.renderArrowDown();
    }

    return this.renderArrowRight();
  }

  renderStatus() {
    if (this.props.isExpanded) {
      return null;
    }

    if (!this.props.shouldRender) {
      return (
        <Button
          onClick={this.handleCreate}
          title="Сформировать"
        />
      );
    }

    if (this.props.inProgress) {
      return (
        <s.Status>
          <s.Loader>
            <Loader
              size={16}
            />
          </s.Loader>
          <s.Text>Анализ текста документа</s.Text>
        </s.Status>
      );
    }

    if (this.props.hasError) {
      const path = iconsPath.get('allert');
      const title = `Невозможно сформировать ${this.props.errorTitle}`;

      return (
        <>
          <s.Icon>
            <Icon
              path={path}
              color="#6c7b89"
            />
          </s.Icon>
          <s.Text>{title}</s.Text>
        </>
      );
    }

    return null;
  }

  renderRaiting() {
    if (!this.props.isExpanded) {
      return null;
    }

    return (
      <s.Status>
        <Rating value={this.props.rating} onChange={this.handleRatingUpdate} />
      </s.Status>
    );
  }

  render() {
    return (
      <s.Root>
        <s.Title>
          {this.renderArrowIcon()}
          <s.TitleContent>{this.props.title}</s.TitleContent>
          {this.renderBetaIcon()}
        </s.Title>
        <s.Status>
          {this.renderStatus()}
          {this.renderRaiting()}
        </s.Status>
      </s.Root>
    );
  }

}

export default Header;
