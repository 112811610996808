import { TabId } from '../redux/state';

import { AttributesConnect } from './attributes.connect';
import { TableOfContentsConnect } from './tableOfContents.connect';
import { EditionsConnect } from './editions.connect';

export const tabTypes = {
  attributes: {
    id: TabId.attributes,
    connector: AttributesConnect,
    title: 'Атрибуты',
  },
  editions: {
    id: TabId.editions,
    connector: EditionsConnect,
    title: 'Редакции',
  },
  tableOfContents: {
    id: TabId.tableOfContents,
    connector: TableOfContentsConnect,
    title: 'Оглавление',
  },
};

export function prepareTabs() {
  const tabs = [];
  tabs.push(tabTypes.tableOfContents);
  tabs.push(tabTypes.attributes);
  tabs.push(tabTypes.editions);

  return {
    tabs,
  };
}

export default tabTypes;
