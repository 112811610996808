import React from 'react';

import { IconButton } from '@components/buttons';
import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { Menu } from './menu';

export interface SettingsDropdownProps {
  documentId: number;
  disabled: boolean;
}

export const SettingsDropdown = ({ documentId, disabled = false }: SettingsDropdownProps) => {

  const renderInput = (p) => <IconButton {...p} disabled={disabled} iconName="hamburger" />;
  const renderMenu = (p) => <Menu {...p} documentId={documentId} />;

  return (
    <BaseDropdown
      usePortal
      maxWidth={200}
      disabled={disabled}
      placement="top-start"
      renderInput={renderInput}
      renderMenu={renderMenu}
    />
  );
};

export default SettingsDropdown;
