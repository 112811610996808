import React from 'react';

import s from './annotationContent.style';

interface Props {
  children: React.ReactNode;
}

export const AnnotationContent: React.FC<Props> = ({ children }) => (
  <s.Root>
    {children}
  </s.Root>
);

export default AnnotationContent;
