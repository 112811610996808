import {
  toggleAnnotationReduce,
  ActionData,
} from './toggleAnnotation.reduce';

const actionType = 'REGULATION/ANNOTATIONS/TOGGLE_EXPANDED';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const toggleAnnotation = {
  actionType,
  createAction,
  reduce: toggleAnnotationReduce,
};

export default toggleAnnotation;
