import React from 'react';

import { ChatView } from './chatView';
import { DefaultMessages } from './defaultMessages';
import { InputView } from './inputView';

import type { MessageProps } from './message';
import type { DefaultMessageProps } from './defaultMessages';

import s from './aiChat.style';

interface AIChatProps {
  documentId: number;
  messages: MessageProps[];
  defaultMessages: DefaultMessageProps[];
  disable: boolean;
  answerLoading: boolean;
  messagesLoading: boolean;
  defaultMessagesLoading: boolean;
  onFetch(): void;
  onUnmount(): void;
  onSend(body: string): void;
}

export const AIChat = (props: AIChatProps) => {
  React.useEffect(() => {
    props.onFetch();

    return () => {
      props.onUnmount();
    };
  }, [props.documentId]);

  const isOptionsActive = props.messages.length > 1;

  return (
    <s.Root>
      <ChatView
        documentId={props.documentId}
        items={props.messages}
        loading={props.messagesLoading}
        answerLoading={props.answerLoading}
      />
      <DefaultMessages
        items={props.defaultMessages}
        loading={props.defaultMessagesLoading}
        disable={props.disable}
        onClick={props.onSend}
      />
      <InputView
        documentId={props.documentId}
        optionsDisable={!isOptionsActive}
        disable={props.messagesLoading || props.disable}
        onSend={props.onSend}
      />
    </s.Root>

  );
};

export default AIChat;
