import React from 'react';

import s from './empty.style';

export const Empty = () => (
  <s.Root>
    Ничего не найдено
  </s.Root>
);

export default Empty;
