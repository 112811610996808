import type {
  Rating,
  AnnotationItem,
} from './annotation';

import {
  StateBuilder,
  ProjectId,
} from './state';

export interface ActionData {
  projectId: ProjectId,
  annotation: AnnotationItem,
  rating: Rating;
}

interface Action {
  type: string;
  data: ActionData;
}

export const addAnnotationReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.create(state.annotations.items);

  const annotation = {
    rating: action.data.rating,
    ...action.data.annotation,
  };

  newState.addItem(action.data.projectId, annotation);
  newState.updateFetchError(action.data.projectId, false);
  newState.updateItemProgress(action.data.projectId, false);
  newState.updateExpanded(action.data.projectId);

  const annotations = {
    ...newState.getState(),
  };

  return {
    ...state,
    annotations,
  };
};

export default addAnnotationReduce;
