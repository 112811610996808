import {
  StateBuilder,
  ProjectId,
} from './state';

export interface ActionData {
  projectId: ProjectId
}

interface Action {
  type: string;
  data: ActionData;
}

export const updateFetchErrorReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.create(state.annotations.items);

  newState.updateFetchError(action.data.projectId, true);
  newState.updateItemProgress(action.data.projectId, false);

  const annotations = {
    ...newState.getState(),
  };

  return {
    ...state,
    annotations,
  };
};

export default updateFetchErrorReduce;
