import styled, { keyframes } from 'styled-components';

const style: any = {};

const color = '#a6b0ba';

const dot = keyframes`
  0% { background-color: ${color}; transform: scale(1); }
  50% { background-color: ${color}; transform: scale(2); }
  100% { background-color: ${color}; transform: scale(1); }
`;

style.Root = styled.div`
  align-items: start;
  display: flex;
  justify-content: start;
  flex-shrink: 0;
  height: 32px;
  width: 100%;
  padding-left: 16px;
`;

style.Dot = styled.div`
  animation: ${dot} ease-in-out 1s infinite;
  background-color: grey;
  display: inline-block;
  border-radius: 50%;
  height: 4px;
  margin: 4px;
  width: 4px;

  :nth-of-type(2) {
    animation-delay: 0.2s;
  }

  :nth-of-type(3) {
    animation-delay: 0.3s;
  }

  :nth-of-type(4) {
    animation-delay: 0.4s;
  }
`;

style.Title = styled.span`
  font-size: 11px;
  padding-left: 8px;
  color: ${color};
`;

export default style;
