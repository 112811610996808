import React, { useState } from 'react';

import { IconButton } from '@components/buttons';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { PackageMenu } from '../packageMenu/packageMenu';

type PackageId = number;

interface SettingsPopoverProps {
  id: PackageId;
  isOwner: boolean;
  disabled: boolean;
}

export const Settings = ({ id, disabled, isOwner }: SettingsPopoverProps) => {
  const [wide, setWide] = useState(false);
  const [level, setLevel] = useState(0);

  const handleLevelChange = (params: { wide: boolean; level: number }) => {
    setWide(params.wide);
    setLevel(params.level);
  };

  const renderMenu = (p) => (
    <PackageMenu id={id} isOwner={isOwner} {...p} onLevelChange={handleLevelChange} />
  );

  return (
    <BaseDropdown
      usePortal
      wide={wide}
      level={level}
      disabled={disabled}
      forceLeftRecalculate
      onLevelChange={handleLevelChange}
      placement="bottom-end"
      renderInput={(p) => <IconButton {...p} disabled={disabled} iconName="kebab" />}
      renderMenu={(p) => renderMenu(p)}
    />
  );
};

export default Settings;
