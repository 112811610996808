import React from 'react';

import { TabsConnect } from './tabs.connect';

import s from './sidebar.style';

interface TabProps {
  viewOnly: boolean;
}

interface SidebarProps {
  isViewOnly: boolean;
  Tab: new () => React.Component<TabProps>;
}

export class Sidebar extends React.PureComponent<SidebarProps> {

  render() {
    return (
      <s.Root
        id="sidebar"
      >
        <s.Sidebar>
          <s.Header>
            <TabsConnect />
          </s.Header>
          <s.Content>
            <this.props.Tab viewOnly={this.props.isViewOnly} />
          </s.Content>
        </s.Sidebar>
      </s.Root>
    );

  }

}

export default Sidebar;
