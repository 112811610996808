import styled from 'styled-components';

interface Props {
  theme: any;
}

const style: any = {};

style.Root = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  padding-top: 16px;

  background: ${(props: Props) => props.theme.colors.white};
  overflow-y: auto;
`;

style.Content = styled.div``;

style.Loader = styled.div``;

export default style;
