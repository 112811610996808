import http from '@http';

import { RESTPath } from '@router/path.rest';

import type { Id } from '@router/path.rest';

import type {
  Id as MessageId,
  Rating,
} from './state';

export interface RequestParams {
  documentId: Id;
  messageId: MessageId;
  rating: Rating;
}

export function httpVoteMessage(requestParams: RequestParams) {

  const request = {
    url: RESTPath.aiChat.document.sendVote(requestParams),
    data: {
      rating: requestParams.rating,
    },
  };

  return http
    .put(request)
    .then(http.handleResponse)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);

}

export default httpVoteMessage;
