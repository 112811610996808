import { connect } from 'react-redux';

import { notifierManager } from '@components/notification';

import { voteMessageThunk } from './redux/voteMessage.thunk';

import { MessageControls } from './messageControls';

export function sendNotification() {
  const id = 'CASELOOK_AI_CHAT_ANSWER_IS_COPIED';
  const message = 'Ответ скопирован';
  notifierManager.done({ id, message });
  setTimeout(() => {
    notifierManager.remove({ id });
  }, 2000);
}

export async function setClipboard(text) {
  const type = 'text/plain';
  const blob = new Blob([text], { type });
  const data = [new ClipboardItem({ [type]: blob })];
  await navigator.clipboard.write(data);
}

const mapStateToProps = (state, ownProps) => {
  const message = state.aiChat.messages.itemsMap.get(ownProps.id);

  return {
    vote: message.rating,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCopyToBuffer() {
    const id = `caselook-ai-chat-document-id-${ownProps.id}`;
    const element = document.getElementById(id).innerText;
    setClipboard(element);
    sendNotification();
  },
  onVote(rating: string) {
    const data = {
      parentId: ownProps.parentId,
      id: ownProps.id,
      rating,
    };

    voteMessageThunk(dispatch, data);
  },
});

export const MessageControlsConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(MessageControls);

export default MessageControlsConnect;
