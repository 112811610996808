import styled, { css } from 'styled-components';
import { Owner } from './message';

interface Props {
  theme: any;
  align: 'start' | 'end';
  type: Owner.user | Owner.bot;
}

const style: any = {};

style.Root = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 16px 24px;
  align-items: ${(props: Props) => props.align};
`;

style.Message = styled.div`
  border-radius: 16px;
  padding: 0 0 8px;
  font-size: 13px;
  line-height: 16px;

  background: ${(props: Props) => props.theme.colors.white};

  ${(props: Props) => props.type === Owner.user && css`
      background: ${props.theme.colors.defaultButton};
      padding: 8px 12px;
  `}

  > div {
    > h1, h2, h3, h4 {
      :first-child {
        margin-top: 0;
      }
    }
  }

  h1, h2, h3, h4 {
    margin: 15px 0 14px;
    font-weight: 600;
  }

  h1 + ul {
    padding-top: 0;
  }

  h2 + ul {
    padding-top: 0;
  }

  h3 + ul {
    padding-top: 0;
  }

  h4 + ul {
    padding-top: 0;
  }

  h1 {
    font-size: 16px;
  }

  h2 {
    font-size: 15px;
  }

  h3 {
    font-size: 14px;
  }

  ol, ul {
    padding-top: 8px;
    padding-inline-start: 26px;
    margin: 0;
  }

  ul li:last-child {
    padding-bottom: 0;
  }

  li {
    padding-bottom: 16px;
    padding-left: 10px;
    margin: 0;
  }
`;

export default style;
