import React from 'react';

import s from './message.style';

export enum Owner {
  user = 'question',
  bot = 'answer',
}

export type MessageOwner = Owner.user | Owner.bot;

export interface MessageProps {
  id: string;
  body: string;
  controls?: React.FC;
  type: MessageOwner;
}

function prepareMarkup(body) {
  return {
    __html: `<div>${body}</div>`,
  };
}

export const Message = (props: MessageProps) => {

  function renderControls() {
    if (!props.controls) {
      return null;
    }

    return props.controls;
  }

  const align = props.type === Owner.user ? 'end' : 'start';
  const id = `caselook-ai-chat-document-id-${props.id}`;

  return (
    <s.Root
      align={align}
    >
      <s.Message
        id={id}
        type={props.type}
        dangerouslySetInnerHTML={prepareMarkup(props.body)}
      />
      {renderControls()}
    </s.Root>
  );
};

export default Message;
