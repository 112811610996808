export enum Id {
  date = 'date',
  branchOfLegislation = 'branchOfLegislation',
  phase = 'phase',
  author = 'author',
  projectType = 'projectType',
  text = 'text',
}

export type Data = any;

export interface PresetsProps {
  id: number;
  title: string;
}

export interface Choice {
  id: string;
  title: string;
}

export type MultiChoice = Choice[];

interface Item {
  id: string;
  name: string;
}

export interface AutocompleteDTO {
  counter: number;
  result: Item[];
}

interface Abstract {
  createFromScratch: (
    data: Data,
    presets: PresetsProps,
  ) => void;
  getItemsForSuggest?: (query: string) => Promise<AutocompleteDTO>,
  undecorate: () => void;
  decorate: () => void;
  validate: () => void;
}

export abstract class AbstractFilterBlock implements Abstract {

  data: Data;

  constructor() {
    if (new.target === AbstractFilterBlock) {
      throw new TypeError('Cannot construct Abstract instances directly');
    }

    this.data = null;
  }

  protected setData(data: Data): void {
    this.data = { ...data };
  }

  getData = (): Data => this.data;

  abstract createFromScratch(
    data: Data, presets: PresetsProps
  ): void;

  abstract getItemsForSuggest(query: string): Promise<any>;

  abstract decorate(): void;

  abstract undecorate(): void;

  abstract validate(): void;

}

export default AbstractFilterBlock;
