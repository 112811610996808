import type { Rating } from './annotation';

import {
  StateBuilder,
  ProjectId,
} from './state';

export interface ActionData {
  projectId: ProjectId;
  value: Rating;
}

interface Action {
  type: string;
  data: ActionData;
}

export const updateAnnotationRatingReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.create(state.annotations.items);
  newState.updateItemRating(action.data.projectId, action.data.value);

  const annotations = {
    ...newState.getState(),
  };

  return {
    ...state,
    annotations,
  };
};

export default updateAnnotationRatingReduce;
