import { StateBuilder as FoldersStateBuilder } from '../sidebar/folders/redux/state';
import { StateBuilder as SmartFoldersStateBuilder } from '../sidebar/filters/redux/state';
import { StateBuilder as ProjectsStateBuilder } from '../workspace/redux/state';
import { StateBuilder as FiltersStateBuilder } from '../workspace/toolbar/redux/state';
import { StateBuilder as SearchFoldersStateBuilder } from './searchFolders/state';
import { StateBuilder as ChatStateBuilder } from './chat/state';
import { StateBuilder as UsersStateBuilder } from './users/state';
import { StateBuilder as EventsStateBuilder } from '../workspace/calendar/redux/state';
import { StateBuilder as CommentsStateBuilder } from '../workspace/comments/redux/state';
import { StateBuilder as PreviewStateBuilder } from './preview/state';
import { StateBuilder as AnnotationsStateBuilder } from '../workspace/projects/annotation/redux/state';

export class StateBuilder {

  static createState() {
    return {
      folders: FoldersStateBuilder.createState(),
      smartFolders: SmartFoldersStateBuilder.createState(),
      projects: ProjectsStateBuilder.createState(),
      filters: FiltersStateBuilder.createState(),
      searchResult: SearchFoldersStateBuilder.createState(),
      chat: ChatStateBuilder.createState(),
      events: EventsStateBuilder.createState(),
      comments: CommentsStateBuilder.createState(),
      preview: PreviewStateBuilder.createState(),
      users: UsersStateBuilder.createState(),
      annotations: AnnotationsStateBuilder.createState(),
    };
  }

}

export default StateBuilder;
