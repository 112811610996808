import React, {
  useState,
  useEffect,
} from 'react';

import { Counter } from '../../counter/counter';

import s from './selectAll.style';

interface SelectAllProps {
  counter: number;
  allItemsSelected: boolean;
  onClick: (selectAll: boolean) => void;
}

export const SelectAll: React.FC<SelectAllProps> = ({ counter, allItemsSelected, onClick }) => {
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setSelectAll(allItemsSelected);
  }, [allItemsSelected]);

  const renderButtonTitle = () => {
    if (!selectAll) {
      return 'Выбрать все';
    }

    return 'Исключить все';
  };

  const handleClick = () => {
    setSelectAll(!selectAll);

    onClick(selectAll);
  };

  return (
    <s.Root>
      <Counter
        counter={counter}
      />
      <s.Button
        onClick={handleClick}
      >
        {renderButtonTitle()}
      </s.Button>
    </s.Root>
  );
};

export default SelectAll;
