import {
  css,
  styled,
} from '@components/styled';

interface Props {
  theme: any;
  isActive: boolean;
  shouldRender: boolean;
  isAvatarHeightDominant: boolean;
}

const style: any = {};

style.Root = styled.div`
  border-top: 1px solid rgb(223, 226, 230);
  flex-shrink: 0;
`;

style.Message = styled.div`
  position: relative;
`;

style.Photo = styled.div`
  position: absolute;
  z-index: 1;
  width: 32px;
  height: 32px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 8px;
  left: 16px;
  top: 16px;

  > img {
    height: 100%;
    width: auto;

    ${(props: Props) => props.isAvatarHeightDominant && css`
      height: auto;
      width: 100%;
    `}
  }
`;

style.AvatarIcon = styled.div`
  width: 32px;
  height: 32px;
  background: ${(props: Props) => props.theme.colors.defaultButton};
`;

style.TextArea = styled.div`
  background: white;

  label {
    left: 64px;
    top: 26px;
  }


  .ql-editor {
    padding: 26px 16px 16px 62px;
    max-height: 384px;
    overflow: auto;
  }

  .ql-editor.ql-blank::before {
    left: 62px;
  }
`;

style.Actions = styled.div`
  font-size: 13px;
  display: flex;
  justify-content: flex-start;
  height: 48px;
  display: none;
  background: ${(props: Props) => props.theme.colors.white};


  ${(props: Props) => props.isActive && css`
    background: #f3f7fc;
  `}

  ${(props: Props) => props.shouldRender && css`
    display: flex;
  `}
`;

export default style;
