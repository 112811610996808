import { fetchDefaultMessagesReduce } from './fetchDefaultMessages.reduce';

import type { ActionData } from './fetchDefaultMessages.reduce';

const actionType = 'CASELOOK/DOCUMENT/AI_CHAT/FETCH_DEFAULT_MESSAGES';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const fetchDefaultMessages = {
  actionType,
  createAction,
  reduce: fetchDefaultMessagesReduce,
};

export default fetchDefaultMessages;
