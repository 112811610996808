export type Id = number;

export type ProjectNumber = string;

export type Loading = boolean;

export type ModifiedLinkDTO = {
  id: string;
  title: string;
};

export interface ProjectDTO {
  id: Id;
  project_number: ProjectNumber;
  title: string;
  folder_count: number;
  start_date: string;
  last_event_date: string;
  modified_links: ModifiedLinkDTO[];
  last_event: string;
  current_phase: string;
  source: {
    title: string;
    link: string;
  };
  author: string;
  type: string;
  branch_of_legislation: string;
  logo: string;
  is_new: boolean;
}

export interface BodyBlock {
  id: string;
  body: string;
}

export interface Phase {
  title: string;
  events: Event[];
}

interface PreviewDocument {
  body: BodyBlock[];
  loading: Loading;
}

interface Phases {
  items: Phase[];
  loading: Loading;
}

export type ModifiedLink = {
  id: string;
  name: string;
};

export interface ProjectInformation {
  id: Id;
  projectNumber: ProjectNumber;
  title: string;
  startDate: string;
  source: {
    title: string,
    link: string,
  };
  author: string;
  type: string;
  branchOfLegislation: string;
  modifiedLinks: ModifiedLink[];
  loading: boolean;
}

interface State {
  id: Id;
  project: PreviewDocument;
  note: PreviewDocument;
  attributes: ProjectInformation;
  phases: Phases;
  loading: Loading;
}

export class StateBuilder {

  private state: State;

  static createState() {
    return {};
  }

  constructor(state: State) {
    this.state = state;
  }

  private buildModifiedLinks(data) {
    return data.map((item) => ({
      id: item.id,
      name: item.title,
    }));
  }

  public createEmptyProject(id: Id) {
    this.state = {
      ...this.state,
      [id]: {
        project: {
          body: [],
          loading: true,
        },
        note: {
          body: [],
          loading: true,
        },
        attributes: {
          projectNumber: null,
          title: null,
          startDate: null,
          source: {
            title: null,
          },
          author: null,
          type: null,
          branchOfLegislation: null,
          modifiedLinks: [],
          loading: true,
        },
        phases: {
          items: [],
          loading: true,
        },
      },
    };
  }

  public buildPreviewNote(id: Id, body) {
    this.state = {
      ...this.state,
      [id]: {
        ...this.state[id],
        note: {
          body,
          loading: false,
        },
      },
    };
  }

  public buildPreviewPhases(id: Id, items) {
    this.state = {
      ...this.state,
      [id]: {
        ...this.state[id],
        phases: {
          items,
          loading: false,
        },
      },
    };
  }

  public buildPreviewProject(id: Id, body) {
    this.state = {
      ...this.state,
      [id]: {
        ...this.state[id],
        project: {
          body,
          loading: false,
        },
      },
    };
  }

  public buildPreviewAttributes(id: Id, data) {
    this.state = {
      ...this.state,
      [id]: {
        ...this.state[id],
        attributes: {
          projectNumber: data.project_number,
          startDate: data.start_date,
          title: data.title,
          author: data.author,
          type: data.type,
          branchOfLegislation: data.branch_of_legislation,
          modifiedLinks: this.buildModifiedLinks(data.modified_links),
          source: {
            title: data.source.title,
            link: data.source.link,
          },
          loading: false,
        },
      },
    };
  }

  public getState() {
    return this.state;
  }

}

export default StateBuilder;
