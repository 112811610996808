import React from 'react';

import { TabsConnect } from './tabs.connect';
import s from './sidebar.style';

interface InformationProps {
  Tab: new () => React.Component;
}

export class Sidebar extends React.PureComponent<InformationProps> {

  render() {
    return (
      <s.Root>
        <s.Header>
          <TabsConnect />
        </s.Header>
        <s.Content>
          <this.props.Tab />
        </s.Content>
      </s.Root>
    );
  }

}

export default Sidebar;
