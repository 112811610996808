import React, {
  useRef,
  useState,
  useEffect,
} from 'react';

import {
  Popover,
  Placement,
} from './popover/popover';

import s from './baseDropdown.style';

export type RenderInputElement = (
  {
    closePopover,
  }
  : {
    closePopover: () => void,
    isActive: boolean
  }
) => React.ReactElement;

export type RenderElement = (
  {
    closePopover,
  }
  : {
    closePopover: () => void
  }
) => React.ReactElement;

interface BaseDropdownProps {
  disabled?: boolean;
  usePortal?: boolean;
  maxWidth?: number;
  wide?: boolean;
  level?: number;
  placement?: Placement;
  forceLeftRecalculate?: boolean;
  leftMargin?: number;
  useAnchorWidth?: boolean;
  renderInput: RenderInputElement;
  renderMenu: RenderElement;
  onMenuVisible?: (menuVisible: boolean) => void;
  // @ts-ignore
  onLevelChange?: ({ wide: boolean, level: number }) => void;
  onClickOutside?(): void;
  forceMenuVisible?: boolean;
}

export const BaseDropdown: React.FC<BaseDropdownProps> = ({ ...props }: BaseDropdownProps) => {
  const toggleRef = useRef(null);
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    if (!props.onMenuVisible) {
      return;
    }

    props.onMenuVisible(menuVisible);
  }, [menuVisible]);

  const handleClickOutside = () => {
    if (props.onClickOutside) {
      props.onClickOutside();
    }

    setMenuVisible(false);
  };

  const handleDropdownVisibleFalse = () => {
    if (props.onLevelChange) {
      props.onLevelChange({ wide: false, level: 0 });
    }

    setMenuVisible(false);
  };

  const handleToggleVisible = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if (props.disabled) {
      return;
    }

    const shouldNotHidden = menuVisible && props.forceMenuVisible;
    if (shouldNotHidden) {
      return;
    }

    setMenuVisible(!menuVisible);
  };

  return (
    <s.BaseDropdown>
      <Popover
        onClickOutside={handleClickOutside}
        visible={menuVisible}
        // @ts-ignore
        anchorRef={toggleRef.current}
        {...props}
      >
        <s.MenuWrapper>
          {props.renderMenu({
            closePopover: handleDropdownVisibleFalse,
          })}
        </s.MenuWrapper>
      </Popover>
      <s.Toggle
        ref={toggleRef}
        isFocused={menuVisible}
        onClick={handleToggleVisible}
      >
        {props.renderInput({
          closePopover: handleDropdownVisibleFalse,
          isActive: menuVisible,
        })}
      </s.Toggle>
    </s.BaseDropdown>
  );
};

BaseDropdown.defaultProps = {
  disabled: false,
  onMenuVisible: () => {},
  forceMenuVisible: false,
  useAnchorWidth: false,
};

export default BaseDropdown;
