import { v4 as uuid } from 'uuid';
import { Map } from 'immutable';

export enum MessageType {
  question = 'question',
  answer = 'answer',
}

export enum Rating {
  good = 'good',
  bad = 'bad',
}

export type Id = string;

export type Loading = boolean;

export type Body = string;

export type Ratings = Rating.good | Rating.bad;

export interface Message {
  id?: Id;
  type: MessageType.question | MessageType.answer;
  body: Body;
  rating?: Rating;
}

export interface Messages {
  itemsList: Id[];
  itemsMap: Map<Id, Message>;
  loading: Loading;
}

export interface DefaultMessages {
  items: Body[],
  loading: Loading;
}

export interface State {
  messages: Messages;
  defaultMessages: DefaultMessages;
}

export class StateBuilder {

  private state: State;

  static createState() {
    return {
      messages: {
        itemsList: [],
        itemsMap: Map(),
        loading: true,
      },
      defaultMessages: {
        items: [],
        loading: true,
      },
    };
  }

  constructor(state: State) {
    this.state = state;
  }

  public addMessage(message: Message) {
    const item = this.buildMessage(message);
    this.addToItemsList(item.id);
    this.addToItemsMap(item.id, item);
  }

  public addMessages(items: Message[]) {
    items.forEach((item) => {
      this.addMessage(item);
    });
  }

  public addDefaultMessages(items: Body[]) {
    this.state = {
      ...this.state,
      defaultMessages: {
        ...this.state.defaultMessages,
        items,
      },
    };
  }

  public updateRating({ id, rating }: { id: Id, rating: Rating }) {
    const item = this.state.messages.itemsMap.get(id);
    const newItem = this.buildMessage({ ...item, rating });

    this.state.messages.itemsMap = this.state.messages.itemsMap.set(id, newItem);
  }

  public setMessagesLoading(loading: boolean) {
    this.state = {
      ...this.state,
      messages: {
        ...this.state.messages,
        loading,
      },
    };
  }

  public setDefaultMessagesLoading(loading: boolean) {
    this.state = {
      ...this.state,
      defaultMessages: {
        ...this.state.defaultMessages,
        loading,
      },
    };
  }

  public getState() {
    return this.state;
  }

  private addToItemsList(id) {
    this.state.messages.itemsList.push(id);
  }

  private addToItemsMap(id, message) {
    this.state.messages.itemsMap = this.state.messages.itemsMap.set(id, message);
  }

  private buildMessage(message): Message {
    return {
      id: message.id || uuid(),
      body: message.body,
      type: message.type,
      rating: message.rating || null,
    };
  }

}

export default StateBuilder;
