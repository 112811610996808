import { StateBuilder } from './state';

import type { Body } from './state';

export type ActionData = Body[];

export interface Action {
  type: string;
  data: ActionData;
}

export const fetchDefaultMessagesReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state);

  newState.addDefaultMessages(action.data);
  newState.setDefaultMessagesLoading(false);

  return newState.getState();
};

export default fetchDefaultMessagesReduce;
