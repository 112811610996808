import React from 'react';

import s from './additionBlock.style';

interface Props {
  children: React.ReactNode;
}

export const AdditionBlock: React.FC<Props> = (props: Props) => (
  <s.Root>
    {props.children}
  </s.Root>
);

export default AdditionBlock;
