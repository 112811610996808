import React from 'react';

import { DefaultMessage } from './defaultMessage';

import s from './defaultMessages.style';

export interface DefaultMessageProps {
  id: string;
  body: string;
}

export interface DefaultMessagesProps {
  onClick(body: string): void;
  loading: boolean;
  disable: boolean;
  items: DefaultMessageProps[];
}

export const DefaultMessages = (props: DefaultMessagesProps) => {
  const items = props.items.map(
    (propItem) => (
      <DefaultMessage
        disable={props.disable}
        key={propItem.id}
        body={propItem.body}
        onClick={props.onClick}
      />
    ),
  );

  return (
    <s.Root>
      {items}
    </s.Root>
  );
};

export default DefaultMessages;
