import { startAnnotationProgressReduce } from './startAnnotationProgress.reduce';

import type { ActionData } from './startAnnotationProgress.reduce';

const actionType = 'REGULATION/ANNOTATIONS/START_ANNOTATION_PROGRESS';

const createAction = (data: ActionData) => ({
  type: actionType,
  data,
});

export const startAnnotationProgress = {
  actionType,
  createAction,
  reduce: startAnnotationProgressReduce,
};

export default startAnnotationProgress;
