import styled, { css } from 'styled-components';

interface Props {
  theme: any;
  disable: boolean;
}

const style: any = {};

style.Root = styled.div`
  flex-wrap: wrap;
  display: flex;
  flex-shrink: 0;
  padding: 8px 16px;
  position: relative;

  :before {
    position: absolute;
    content: '';

    top: -32px;
    left: 0;
    width: 100%;
    height: 32px;

    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  }
`;

style.Message = styled.div`
  font: 11px Roboto-Regular;
  color: ${(props: Props) => props.theme.colors.stone};
  border-radius: 12px;
  padding: 4px 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  background: ${(props: Props) => props.theme.colors.defaultButton};
  user-select: none;
  cursor: pointer;

  :hover {
    color: ${(props: Props) => props.theme.colors.white};
    background: ${(props: Props) => props.theme.colors.silver};
  }

  ${(props: Props) => props.disable && css`
    :hover {
      color: ${props.theme.colors.stone};
      background: ${props.theme.colors.defaultButton};
      cursor: default;
    }
  `};
`;

export default style;
