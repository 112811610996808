import { connect } from 'react-redux';

import { Annotation } from '@layouts/documentHelpers/annotation/annotation';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from 'userpilot';

import { UserRoleType } from '@pages/userPages/redux/state';
import { toggleAnnotation } from '../annotation/redux/toggleAnnotation';
import { updateAnnotationRatingThunk } from '../annotation/redux/updateAnnotationRating.thunk';

import { fetchAnnotationThunk } from '../annotation/redux/fetchAnnotation.thunk';

const buildAdditionContent = (change, index) => `${index + 1}. <strong>${change.title}:</strong> ${change.description}`;

const transformChanges = (item) => {
  const additions = [];

  const addition = item
    .map(buildAdditionContent)
    .join('<br/><br/>');

  additions.push(addition);

  return additions;
};

const mapStateToProps = (state, ownProps) => {
  const isReal = state.currentUser.role !== UserRoleType.guest;
  const annotation = state.regulation.annotations.items.get(ownProps.projectId);

  const body = annotation && annotation.body;
  const changes = annotation && annotation.changes;
  const additions = changes && transformChanges(changes);
  const hasError = annotation && annotation.fetchError;
  const inProgress = annotation && annotation.inProgress;
  const isExpanded = annotation && annotation.isExpanded;
  const rating = annotation && annotation.rating;

  return {
    id: ownProps.projectId,
    rating,
    isReal,
    shouldRender: !!annotation,
    inProgress,
    isExpanded,
    hasError,
    body,
    additions,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCreate() {
    fetchAnnotationThunk(dispatch, { projectId: ownProps.projectId });

    sendAmplitudeData('regulation:annotation create', { projectId: ownProps.projectId });
    Userpilot.track('regulation:annotation create', { projectId: ownProps.projectId });
  },
  onRatingUpdate(data) {
    updateAnnotationRatingThunk(dispatch, { projectId: ownProps.projectId, value: data.value });

    sendAmplitudeData('regulation:annotation rating update', { projectId: ownProps.projectId, rating: data.value });
    Userpilot.track('regulation:annotation rating update', { projectId: ownProps.projectId, rating: data.value });
  },
  onToggle() {
    dispatch(toggleAnnotation.createAction({ projectId: ownProps.projectId }));

    sendAmplitudeData('caselook:annotation open', { projectId: ownProps.projectId });
    Userpilot.track('caselook:annotation open', { projectId: ownProps.projectId });
  },
});

export const AnnotationConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Annotation);

export default AnnotationConnect;
