import { ReducerBuilder } from '@redux/reducer.builder';

import { StateBuilder } from './state';
import { startAnnotationProgress } from './startAnnotationProgress';
import { addAnnotation } from './addAnnotation';
import { updateAnnotationRating } from './updateAnnotationRating';
import { toggleAnnotation } from './toggleAnnotation';
import { updateFetchError } from './updateFetchError';

export const reducerBuilder = new ReducerBuilder([
  startAnnotationProgress,
  addAnnotation,
  updateAnnotationRating,
  toggleAnnotation,
  updateFetchError,
]);

reducerBuilder.setInitialState(StateBuilder.createState());

export default reducerBuilder;
