import { Dispatch } from 'redux';

import { Id } from '@router/path.rest';

import { httpFetchMessages } from './fetchMessages.http';
import { fetchMessages } from './fetchMessages';

import { httpFetchDefaultMessages } from './fetchDefaultMessages.http';
import { fetchDefaultMessages } from './fetchDefaultMessages';

export interface Params {
  id: Id;
}

export class FetchMessagesThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params) {
    const messages = await this.fetchMessages(params.id);
    this.dispatchAddMessages(messages);

    const defaultMessages = await this.fetchDefaultMessages(params.id);
    this.dispatchAddDefaultMessages(defaultMessages);
  }

  private async fetchMessages(id) {
    try {
      return await httpFetchMessages(id);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private async fetchDefaultMessages(id) {
    try {
      return await httpFetchDefaultMessages(id);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchAddMessages(params) {
    this.dispatch(fetchMessages.createAction(params.result));
  }

  private dispatchAddDefaultMessages(params) {
    this.dispatch(fetchDefaultMessages.createAction(params.result));
  }

}

export function fetchMessagesThunk(dispatch, params) {
  const thunk = new FetchMessagesThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default FetchMessagesThunk;
