import React from 'react';

import s from './defaultMessages.style';

export interface DefaultMessageProps {
  body: string;
  disable: boolean;
  onClick(body: string): void;
}

export const DefaultMessage = (props: DefaultMessageProps) => {
  const handleClick = () => {
    if (props.disable) {
      return;
    }

    props.onClick(props.body);
  };

  return <s.Message disable={props.disable} onClick={handleClick}>{props.body}</s.Message>;
};

export default DefaultMessage;
