import { connect } from 'react-redux';

import { ProjectTab } from '@pages/regulation/workspace/projects/preview/projectTab';

import { getPreview } from './preview.connect';

const mapStateToProps = (state, ownProps) => {
  const preview = getPreview(ownProps.id, state.regulation.preview);
  const projectId = ownProps.id;

  return {
    body: preview.project.body,
    loading: preview.project.loading,
    projectId,
  };
};

export const ProjectTabConnect = connect(
  mapStateToProps,
)(ProjectTab);

export default ProjectTabConnect;
