import { Dispatch } from 'redux';

import { Id } from '@router/path.rest';

import { httpSendMessage } from './sendMessage.http';
import { addMessage } from './addMessage';

import { MessageType } from './state';

import type { Body } from './state';

export interface MessageParams {
  id: Id,
  body: Body;
}

export class SendMessageThunk {

  getState;

  private readonly dispatch: Dispatch;

  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  public async invoke(params: MessageParams) {
    this.dispatchAddMessage({ body: params.body }, MessageType.question);
    const answer = await this.sendMessage(params);
    this.dispatchAddMessage(answer, MessageType.answer);
  }

  private async sendMessage(params) {
    try {
      return await httpSendMessage(params);
    } catch (error) {
      throw Error(error.status);
    }
  }

  private dispatchAddMessage(params, type) {
    const data = {
      type,
      body: params.body,
      id: params.id,
    };

    this.dispatch(addMessage.createAction(data));
  }

}

export function sendMessageThunk(dispatch, params) {
  const thunk = new SendMessageThunk(dispatch);

  dispatch((_, getState) => { thunk.getState = getState; });

  thunk.invoke(params);
}

export default SendMessageThunk;
