import React from 'react';

import {
  isEmpty,
  isEqual,
} from 'lodash';

import { Loader } from '@components/loader';
import { Checkbox } from '@components/checkbox/checkbox';

import { SearchField } from './searchField';
import { Scrollable } from './scrollable';
import { Empty } from './empty';

import { Items } from './items';
import { AdditionBlock } from './additionBlock';

import s from './layout.style';

interface ListItem {
  id: string;
  title: string;
}

interface LayoutProps {
  data: ListItem[];
  withSearch: boolean;
  selectAllTitle: string;
  isDefault: boolean;
  onUpdate(data: any): void;
  onFetchData(query: string, page: number): Promise<any>;
  Addition?: React.FC<any>;
}

interface LayoutState {
  query: string;
  loading: boolean;
  items: ListItem[];
  fetchedItems: any;
  selected: Record<number, boolean>;
  page: number;
  counter: number;
}

export class Layout extends React.PureComponent<LayoutProps, LayoutState> {

  private placeholder = 'Поиск';

  state = {
    query: '',
    loading: true,
    items: [],
    fetchedItems: [],
    selected: {},
    page: 1,
    counter: 0,
  };

  componentDidMount() {
    this.buildInitialItems();
    this.buildSelectedState();
    this.fetchItems();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.loading) {
      return;
    }

    if (prevState.page !== this.state.page) {
      this.fetchNextItems();
    }

    if (prevState.query !== this.state.query) {
      this.checkQuery();
    }

    if (isEqual(prevState.selected, this.state.selected)) {
      return;
    }

    this.props.onUpdate(this.state.selected);
  }

  handleReset = () => {
    this.setState({
      selected: {},
    });
  };

  handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setQuery(e.target.value);
  };

  handleItemClick = (item) => {
    this.setState((prevState) => {
      const selected = this.toggleSelected(prevState, item);

      return { selected };

    });
  };

  private handleSelectAll = (selectAll) => {
    this.setState((prevState) => {
      const selected = this.createSelected(prevState, selectAll);

      return { selected };
    });
  };

  setQuery(query) {
    this.setState({
      query,
      page: 1,
    });
  }

  private setLoadinFalse = () => this.setLoading(false);

  private setLoading(loading: boolean) {
    this.setState(() => ({
      loading,
    }));
  }

  cleanItems = (items) => {
    const fetchedItems = items.filter((item) => !this.state.items.some((stateItem) => item.id === stateItem.id));

    return fetchedItems;
  };

  private toggleSelected = (prevState, item) => {
    const actualSelected = { ...prevState.selected };
    if (prevState.selected[item.id]) {
      delete actualSelected[item.id];
    } else {
      actualSelected[item.id] = item;
    }

    return actualSelected;
  };

  private prepareItems = (items) => {
    const preparedItems = items.filter((item) => {
      const title = item.title.toLowerCase();
      const query = this.state.query.toLowerCase();

      return title.includes(query);
    });

    return preparedItems;
  };

  private getFilteredItems = () => {
    const defaultItems = this.prepareItems(this.state.items);
    const fetchedItems = this.prepareItems(this.state.fetchedItems);

    return [...defaultItems, ...fetchedItems];
  };

  private createSelected = (prevState, selectAll) => {
    const actualSelected = { ...prevState.selected };

    const items = this.getFilteredItems();
    items.forEach((item) => {
      if (!selectAll) {
        actualSelected[item.id] = item;
      } else {
        delete actualSelected[item.id];
      }
    });

    return actualSelected;
  };

  private increasePageNumber = () => {
    this.setState((prevState) => ({
      page: prevState.page + 1,
    }));
  };

  private buildInitialItems() {
    this.setState({
      items: this.props.data,
    });
  }

  private checkQuery() {
    if (!this.props.Addition) {
      this.fetchItems();

      return;
    }

    const query = this.state.query.trim();
    const shouldFetch = !query || query.length > 2;
    if (!shouldFetch) {
      return;
    }

    this.fetchItems();
  }

  private async fetchItems() {
    const data = await this.props.onFetchData(this.state.query, this.state.page);
    const fetchedItems = this.cleanItems(data.items);
    const counter = data.counter;

    this.setState(() => ({
      fetchedItems,
      counter,
    }), this.setLoadinFalse);
  }

  private async fetchNextItems() {
    const data = await this.props.onFetchData(this.state.query, this.state.page);
    const fetchedItems = this.cleanItems(data.items);
    const counter = data.counter;

    this.setState((prevState) => ({
      fetchedItems: prevState.fetchedItems.concat(fetchedItems),
      counter,
    }));
  }

  private buildSelectedState() {
    const selected = {};
    this.props.data.forEach((item) => {
      selected[item.id] = item;
    });

    this.setState(() => ({
      selected,
    }));
  }

  renderSearchField = () => {
    if (!this.props.withSearch) {
      return null;
    }

    return (
      <SearchField
        value={this.state.query}
        placeholder={this.placeholder}
        onChange={this.handleSearchQuery}
      />
    );
  };

  renderSelectAll() {
    if (!this.props.withSearch) {
      return null;
    }

    const checked = isEmpty(this.state.selected);

    return (
      <s.DefaultContainer>
        <s.Option
          onClick={this.handleReset}
        >
          <Checkbox
            checked={checked}
            onClick={this.handleReset}
          />
          <s.OptionTitle>
            {this.props.selectAllTitle}
          </s.OptionTitle>
        </s.Option>
      </s.DefaultContainer>
    );
  }

  renderLoader() {
    return (
      <s.Loader>
        <Loader size={32} />
      </s.Loader>
    );
  }

  renderEmptyBlock() {
    return (
      <Empty />
    );
  }

  renderAddition() {
    if (!this.props.Addition) {
      return null;
    }

    const items = this.getFilteredItems();
    const MIN_QUERY_LENGTH = 3;
    const shouldRender = this.state.query.length >= MIN_QUERY_LENGTH;
    if (!shouldRender) {
      return null;
    }

    const allItemsSelected = items.every((item) => this.state.selected[item.id]);

    return (
      <AdditionBlock>
        <this.props.Addition
          onClick={this.handleSelectAll}
          counter={this.state.counter}
          allItemsSelected={allItemsSelected}
        />
      </AdditionBlock>
    );
  }

  renderContent() {
    if (this.state.loading) {
      return this.renderLoader();
    }

    const items = this.getFilteredItems();
    if (!items.length) {
      return this.renderEmptyBlock();
    }

    const defaultItems = this.prepareItems(this.state.items);

    return (
      <s.Wrapper>
        <Items
          items={defaultItems}
          checkedItems={this.state.selected}
          onClick={this.handleItemClick}
        />
        <Items
          items={this.state.fetchedItems}
          checkedItems={this.state.selected}
          onClick={this.handleItemClick}
        />
      </s.Wrapper>
    );
  }

  render() {
    return (
      <s.Root>
        {this.renderSearchField()}
        <Scrollable onFire={this.increasePageNumber}>
          {this.renderSelectAll()}
          {this.renderAddition()}
          {this.renderContent()}
        </Scrollable>
      </s.Root>
    );
  }

}

export default Layout;
