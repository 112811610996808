import { connect } from 'react-redux';

import { tabTypes } from './tabUtils';
import { Sidebar } from './sidebar';

const mapStateToProps = (state) => {
  const { activeTabId } = state.lawDocument.layout.sidebar;

  return {
    Tab: tabTypes[activeTabId].connector,
  };
};

export const SidebarConnect = connect(
  mapStateToProps,
)(Sidebar);

export default SidebarConnect;
