import { StateBuilder } from './state';

import type { Message } from './state';

export type ActionData = Message;

export interface Action {
  type: string;
  data: ActionData;
}

export const addMessageReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder(state);

  newState.addMessage(action.data);

  return {
    ...newState.getState(),
  };
};

export default addMessageReduce;
