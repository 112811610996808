import React from 'react';

import { Item } from './item';

import s from './items.style';

interface ItemProps {
  id: string;
  title: string;
}

interface Props {
  items: ItemProps[];
  checkedItems: any;
  onClick:(item) => void;
}

export const Items = (props: Props) => {
  if (!props.items.length) {
    return null;
  }

  const renderItems = () => props.items.map((item: ItemProps) => (
    <Item
      id={item.id}
      title={item.title}
      checked={props.checkedItems[item.id]}
      onClick={props.onClick}
    />
  ));

  return (
    <s.Root>
      {renderItems()}
    </s.Root>
  );
};

export default Items;
