import { connect } from 'react-redux';

import { Message } from './message';

const mapStateToProps = (state, ownProps) => {
  const message = state.aiChat.messages.itemsMap.get(ownProps.id);

  return {
    type: message.type,
  };
};

export const MessageConnect = connect(
  mapStateToProps,
)(Message);

export default MessageConnect;
