import { ReducerBuilder } from '@redux/reducer.builder';

import { StateBuilder } from './state';

import { fetchMessages } from './fetchMessages';
import { fetchDefaultMessages } from './fetchDefaultMessages';
import { addMessage } from './addMessage';
import { voteMessage } from './voteMessage';
import { resetToInitial } from './resetToInitial';

export const reducerBuilder = new ReducerBuilder([
  fetchMessages,
  fetchDefaultMessages,
  addMessage,
  voteMessage,
  resetToInitial,
]);

reducerBuilder.setInitialState(StateBuilder.createState());

export default reducerBuilder;
