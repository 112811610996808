import React from 'react';

import { IconButton } from '@components/buttons';

import { SettingsDropdown } from './settingsDropdown';

import s from './inputView.style';

export interface InputViewProps {
  optionsDisable: boolean;
  disable: boolean;
  documentId: number;
  onSend(body: string): void;
}

export const InputView = (props: InputViewProps) => {

  const refTextArea = React.useRef(null);

  const refTextMeasure = React.useRef(null);

  const [value, setValue] = React.useState('');
  const [height, setHeight] = React.useState(48);

  React.useEffect(() => {
    const clientHeight = refTextMeasure.current.clientHeight + 2;
    setHeight(clientHeight);
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSend = () => {
    props.onSend(value);
    setValue('');
  };

  const renderLabel = () => {
    if (value.length) {
      return null;
    }

    return <s.Label>Задайте свой вопрос</s.Label>;
  };

  const renderTextArea = () => {
    const { disable } = props;

    return (
      <s.Textarea
        maxLength={500}
        disabled={disable}
        height={height}
        value={value}
        ref={refTextArea}
        onInput={handleInput}
      />
    );
  };

  const renderTextMeasure = () => {
    const newValue = `${value}\n`;

    return (
      <s.TextMeasureWrapper>
        <s.TextMeasure ref={refTextMeasure}>
          {newValue}
        </s.TextMeasure>
      </s.TextMeasureWrapper>
    );
  };

  const isEmpty = value.trim().length < 3;
  const disable = props.disable || isEmpty;

  const renderMenu = () => (
    <s.Menu>
      <SettingsDropdown
        documentId={props.documentId}
        disabled={props.disable || props.optionsDisable}
      />
    </s.Menu>
  );

  const renderAction = () => (
    <s.Action disable={disable}>
      <IconButton
        activeColor="#673ab7"
        disabled={disable}
        onClick={handleSend}
        isPoints
        iconName="send"
      />
    </s.Action>
  );

  return (
    <s.Root>
      {renderTextArea()}
      {renderLabel()}
      {renderMenu()}
      {renderAction()}
      {renderTextMeasure()}
    </s.Root>
  );

};
