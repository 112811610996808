import React, { Component } from 'react';

import { sendAmplitudeData } from '@utils/amplitude';
import { Userpilot } from 'userpilot';

import { iconsPath } from '@components/icons';
import { Checkbox } from '@components/checkbox/checkbox';

import { CellMore } from '@pages/searchResult/documents/list/cellMore';

import {
  Project,
  Id,
} from '../../redux/state';

import { CommentConnect } from './comment.connect';
import { FamiliarizeConnect } from './familiarize.connect';
import { AddFolderConnect } from './addFolder.connect';

import s from './item.style';

interface State {
  showPreview: boolean;
}

interface Props {
  item: Project;
  isSelect: boolean;
  isCheckDisable: boolean;
  isActive: boolean;
  onItemClick(id: Id): void;
  onItemCheck(): void;
  onItemUncheck(): void;
}

export const prepareMarkup = (body) => ({
  __html: `<div>${body}</div>`,
});

export const renderSummary = (text) => {
  if (!text) {
    return null;
  }

  const MAX_LENGTH_TEXT = 350;
  if (text.length > MAX_LENGTH_TEXT) {
    const preparedText = `${text.slice(0, MAX_LENGTH_TEXT)}...`;

    return (
      <s.TitleSummary
        contentTooltipText={text}
      >
        {preparedText}
      </s.TitleSummary>
    );
  }

  return (
    <s.TitleSummary>
      {text}
    </s.TitleSummary>
  );
};

export class Item extends Component<Props, State> {

  handleItemSelect = () => {
    if (!this.props.isSelect) {
      this.props.onItemCheck();

      sendAmplitudeData('regulation: project selected', { projectNumber: this.props.item.projectNumber });
      Userpilot.track('regulation: project selected', { projectNumber: this.props.item.projectNumber });

      return;
    }

    this.props.onItemUncheck();
  };

  handleItemClick = () => {
    this.props.onItemClick(this.props.item.id);
  };

  renderLogo() {
    if (!this.props.item.logo) {
      return (
        <svg
          viewBox="0 0 72 72"
          width="100%"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d={iconsPath.get('crest')} />
        </svg>
      );
    }

    return (
      <img src={this.props.item.logo} alt="crest" />
    );
  }

  render() {
    const {
      item,
    } = this.props;

    return (
      <s.Root
        isActive={this.props.isActive}
        onClick={this.handleItemClick}
      >
        <CellMore>
          <s.CellCheckbox>
            <Checkbox
              checked={this.props.isSelect}
              disable={this.props.isCheckDisable}
              onClick={this.handleItemSelect}
            />
          </s.CellCheckbox>
        </CellMore>
        <CellMore>
          <s.CellLogo>
            <s.Logo>
              {this.renderLogo()}
            </s.Logo>
          </s.CellLogo>
        </CellMore>
        <s.CellDocument>
          <s.BodyCell>
            <s.TitleText bold>{item.author}</s.TitleText>
            <s.TitleLabel>{item.type}</s.TitleLabel>
            <s.TitleLabel marginBottom={8}>{item.branchOfLegislation}</s.TitleLabel>
            <s.TitleText>{item.title}</s.TitleText>
            {renderSummary(item.summary)}
            <s.TitleEvent
              dangerouslySetInnerHTML={prepareMarkup(item.lastEvent)}
            />
          </s.BodyCell>
        </s.CellDocument>
        <s.Actions>
          <s.BodyActions>
            <FamiliarizeConnect id={item.id} />
          </s.BodyActions>
        </s.Actions>
        <s.Actions>
          <s.BodyActions>
            <CommentConnect id={item.id} />
          </s.BodyActions>
        </s.Actions>
        <s.Actions>
          <s.BodyActions>
            <AddFolderConnect id={item.id} from="list" />
          </s.BodyActions>
        </s.Actions>
      </s.Root>
    );
  }

}

export default Item;
