import {
  Id,
  RESTPath,
} from '@router/path.rest';

import http from '@http';

export interface RequestParams {
  projectId: Id;
}

export function httpFetchAnnotation(requestParams: RequestParams) {
  const request = http.get({
    url: RESTPath.regulation.projects.preview.getAnnotation(requestParams),
  });

  return http
    .fetch(request)
    .then(http.parseResponse)
    .catch(http.handlePaymentRequired);
}

export default httpFetchAnnotation;
