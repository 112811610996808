import styled from 'styled-components';

interface Props {
  theme: any;
}

const style: any = {};

style.Root = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
`;

style.Counter = styled.div`
  color: #6c7a89;
`;

style.Button = styled.div`
  color: ${(props: Props) => props.theme.colors.blue};
  text-decoration: none;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

export default style;
