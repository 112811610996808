import styled from 'styled-components';

const style: any = {};

style.Root = styled.div`
  height: 80px;
  width: 100%;
  font-family: Roboto-Regular;
  font-size: 13px;
  font-weight: normal;
  color: #6c7a89;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #dfe2e6;
  padding: 32px 16px;
`;

export default style;
