export type Rating = 'good' | 'bad' | null;

export interface Change {
  title: string;
  description: string;
}

export interface AnnotationItem {
  body: string;
  inProgress: boolean;
  isExpanded: boolean;
  fetchError: boolean;
  changes: Change[];
}

export class Annotation {

  body: string = null;

  rating: Rating = null;

  fetchError = false;

  inProgress = false;

  isExpanded = false;

  changes: Change[] = null;

  createFromScratch(props) {
    this.body = props.body;
    this.rating = props.rating;
    this.fetchError = props.fetchError;
    this.inProgress = props.inProgress;
    this.isExpanded = props.isExpanded;
    this.changes = props.changes;
  }

  setRating(rating: Rating) {
    this.rating = rating;
  }

  setInProgress(inProgress: boolean) {
    this.inProgress = inProgress;
  }

  setIsExpanded(isExpanded: boolean) {
    this.isExpanded = isExpanded;
  }

  setFetchError(fetchError: boolean) {
    this.fetchError = fetchError;
  }

  getData() {
    return {
      body: this.body,
      rating: this.rating,
      inProgress: this.inProgress,
      fetchError: this.fetchError,
      isExpanded: this.isExpanded,
      changes: this.changes,
    };
  }

}

export default Annotation;
