import styled, { css } from 'styled-components';

interface Props {
  theme: any;
  isActive: boolean;
}

const style: any = {};

style.Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
`;

style.Button = styled.div`
  ${(props: Props) => props.isActive && css`
    i {
      color: ${props.theme.colors.blue};
    }
  `}
`;

export default style;
