import React from 'react';

import { BaseDropdown } from '@components/baseDropdown/baseDropdown';

import { TabsMore } from './tabsMore';

import s from './more.style';

interface Tab {
  id: string;
  title: string;
}

interface MenuProps {
  tabs: Tab[];
  activeId: string;
  onClick(tabId: string): void;
}

interface InputProps {
  active: boolean;
  title: string
}

const InputComponent = (props: InputProps) => (
  <s.InputBlock
    active={props.active}
  >
    {props.title}
  </s.InputBlock>
);

const MenuComponent = (props: MenuProps) => (
  <s.MenuBloks>
    <TabsMore
      tabsMore={props.tabs}
      activeId={props.activeId}
      onClick={props.onClick}
    />
  </s.MenuBloks>
);

const isActiveTabMore = (tabsMore, activeId) => {
  const activeTab = tabsMore.find((item) => item.id === activeId);

  return !!activeTab;
};

interface MoreProps {
  title: string;
  tabs: Tab[];
  activeId: string;
  onClick(tabId: string): void;
}

export class More extends React.PureComponent<MoreProps> {

  render() {
    if (!this.props.tabs) {
      return null;
    }

    const active = isActiveTabMore(this.props.tabs, this.props.activeId);

    return (
      <s.Root
        active={active}
      >
        <BaseDropdown
          usePortal
          placement="bottom-start"
          renderInput={(p) => (
            <InputComponent
              active={active}
              title={this.props.title}
              {...p}
            />
          )}
          renderMenu={(p) => (
            <MenuComponent
              activeId={this.props.activeId}
              tabs={this.props.tabs}
              onClick={this.props.onClick}
              {...p}
            />
          )}
        />
      </s.Root>
    );
  }

}

export default More;
