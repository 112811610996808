import io from 'socket.io-client';
import { Map } from 'immutable';

export function getParamsFromUrl(ownProps: any) {
  return ownProps.match.params;
}

export function removeTagsFromText(str) {
  if (!str) {
    return false;
  }

  const newStr = str.toString();

  return newStr.replace(/(<([^>]+)>)/ig, '');
}

export function buildHistoryItem(id = 1, title: string | null = null, loading = true, route: string | null = null) {
  return {
    id,
    title,
    loading,
    route,
  };
}

export function getTitle(documents: { activeItemId: string, itemsMap: Map<string, { title: string }> }) {
  const { activeItemId } = documents;
  const activeItem = documents.itemsMap.get(activeItemId);

  if (!activeItem) {
    return null;
  }

  return activeItem.title;
}

export function removeSpacialCharactersFromValue(value: string) {
  const stringValue = value.toString();

  return stringValue.replace(/[^0-9a-zЁёА-я_ ]/gi, '');
}

export function copyToClipboard(textToCopy: string) {
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(textToCopy);
  }

  const textArea = document.createElement('textarea');
  textArea.value = textToCopy;
  // make the textarea out of viewport
  textArea.style.position = 'fixed';
  textArea.style.left = '-999999px';
  textArea.style.top = '-999999px';
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  return new Promise<void>((res, rej) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    document.execCommand('copy') ? res() : rej();
    textArea.remove();
  });
}

export function buildDangerousHTML(content) {
  return {
    __html: content,
  };
}

class Socket {

  // @ts-ignore
  private socket;

  private channels = {};

  private createSocket = () => {
    // @ts-ignore
    // eslint-disable-next-line new-cap
    this.socket = new io({
      pingInterval: 30000,
      pingTimeout: 30000,
      reconnectionDelay: 10000,
      reconnectionDelayMax: 10000,
      transports: ['websocket', 'polling'],
    });
    this.socket.on('connect_error', () => {
      this.socket.io.opts.transports = ['polling', 'websocket'];
    });

    return this.getSocket();
  };

  public getSocket = () => this.socket;

  constructor() {
    if (this.socket) {
      // eslint-disable-next-line no-constructor-return
      return this.getSocket();
    }

    this.createSocket();
  }

  public setChannel(id: string) {
    // @ts-ignore
    this.channels[id] = {};
  }

  public getChannel(id: string) {
    // @ts-ignore
    return !!this.channels[id];
  }

}

export const socket = new Socket();

export default getParamsFromUrl;
