import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { AIChat } from '@layouts/aiChat/aiChat';
import { Owner } from '@layouts/aiChat/message';
import { fetchMessagesThunk } from '@layouts/aiChat/redux/fetchMessages.thunk';
import { sendMessageThunk } from '@layouts/aiChat/redux/sendMessage.thunk';
import { resetToInitial } from '@layouts/aiChat/redux/resetToInitial';

function buildMessages(items) {
  return items.itemsList.map((id) => items.itemsMap.get(id));
}

function buildDefaultMessages(items) {
  return items.map((item) => ({
    id: uuid(),
    body: item,
  }));
}

const mapStateToProps = (state, ownProps) => {
  const { messages, defaultMessages } = state.aiChat;

  const messagesLoading = messages.loading;
  const defaultMessagesLoading = defaultMessages.loading;

  const viewMessages = buildMessages(messages);

  const lastMessage = viewMessages.slice(-1)[0];

  let disable = false;

  if (messagesLoading) {
    disable = true;
  }

  const answerLoading = lastMessage && lastMessage.type === Owner.user;

  if (answerLoading) {
    disable = true;
  }

  return {
    documentId: ownProps.id,
    messages: viewMessages,
    defaultMessages: buildDefaultMessages(defaultMessages.items),
    answerLoading,
    messagesLoading,
    defaultMessagesLoading,
    disable,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onFetch(id) {
    fetchMessagesThunk(dispatch, { id });
  },
  onUnmount() {
    dispatch(resetToInitial.createAction());
  },
  onSend(body, id) {
    const trimBody = body.trim();
    const data = {
      id,
      body: trimBody,
    };

    sendMessageThunk(dispatch, data);
  },
});

const mergeProps = (stateProps, dispatchPros, ownProps) => ({
  ...stateProps,
  ...dispatchPros,
  ...ownProps,
  onFetch() {
    dispatchPros.onFetch(stateProps.documentId);
  },
  onSend(body: string) {
    dispatchPros.onSend(body, stateProps.documentId);
  },
});

export const AIChatConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(AIChat);

export default AIChatConnect;
