import React from 'react';

import s from './loader.style';

interface LoaderProps {
  title?: string;
}

export const Loader = (props: LoaderProps) => {

  const renderTitle = () => {
    if (!props.title) {
      return null;
    }

    return <s.Title>{props.title}</s.Title>;
  };

  return (
    <s.Root>
      <s.Dot />
      <s.Dot />
      <s.Dot />
      <s.Dot />
      {renderTitle()}
    </s.Root>
  );
};

export default Loader;
