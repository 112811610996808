import {
  StateBuilder,
  ProjectId,
} from './state';

export interface ActionData {
  projectId: ProjectId;
}

interface Action {
  type: string;
  data: ActionData;
}

export const startAnnotationProgressReduce = (state, action: Action) => {
  if (!state) {
    return state;
  }

  const newState = new StateBuilder();
  newState.create(state.annotations.items);
  newState.addEmptyItem(action.data.projectId);
  newState.updateItemProgress(action.data.projectId, true);

  const annotations = {
    ...newState.getState(),
  };

  return {
    ...state,
    annotations,
  };
};

export default startAnnotationProgressReduce;
